import React, { useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { AutoComplete } from "primereact/autocomplete";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { RadioButton } from "primereact/radiobutton";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function DaniosCheckpoint() {
  const SCREEN_CODE = "con0028_danios_x_checkpoint";
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();

  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);
  const columns = [];
  const filterLabelId = [
    11314, 1358, 6070, 467, 468, 10774, 2496, 396, 754, 5044,
  ];
  const filterNames = [];
  const filterColumnsId = [
    1166, 1386, 1387, 1388, 1389, 2131, 6141, 6142, 6143, 6144, 6145, 6146,
    6147, 6148, 6149, 6150,
  ];
  const columnsNames = [];
  for (let i = 0; i < filterLabelId.length; i++) {
    filterNames.push({
      id: filterLabelId[i],
      label: LanguageProvider({
        id: filterLabelId[i],
        alt: "Error Filter Labels",
      }),
    });
  }
  for (let i = 0; i < filterColumnsId.length; i++) {
    columnsNames.push({
      id: filterColumnsId[i],
      label: LanguageProvider({
        id: filterColumnsId[i],
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    2131, 6141, 6142, 6143, 6144, 6145, 6146, 6147, 6148, 6149, 6150, 1082,
    6147, 54, 65, 396, 467, 468, 748, 749, 754, 1166, 1182, 1358, 1386, 1387,
    1388, 1389, 1791, 2496, 5044, 5583, 6070, 10021, 10531, 10774, 11314,
  ];
  for (let i = 0; i < ids.length; i++) {
    mensagens.push({
      id: ids[i],
      label: LanguageProvider({ id: ids[i], alt: "Error Columns Labels" }),
    });
  }

  const [filter, setFilter] = useState({
    checkpoint: null,
    consultaPorCheckpoint: false,
    fecha: [],
    mercados: [],
    origenes: [],
    marcas: [],
    familias: [],
    transportista: null,
    pais: null,
  });

  const [loading, setLoading] = useState(false);

  const [checkpoints, setCheckpoints] = useState([]);
  const [mercados, setMercados] = useState([]);
  const [origenes, setOrigenes] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [transportistas, setTransportistas] = useState([]);
  const [paises, setPaises] = useState([]);
  const [familias, setFamilias] = useState([]);

  async function searchCheckpoints(event: any) {
    await loadCheckpoints(event.query);
  }
  async function loadCheckpoints(query = "") {
    try {
      if (securityFilters.securityValues.account) {
        const { status, data } = await sipcoAxiosService.get(
          "/danios-checkpoint/checkpoint",
          {
            params: {
              codPais: securityFilters.securityValues.country.value,
              codCuenta: securityFilters.securityValues.account.value,
            },
          }
        );
        if (status === 200) {
          if (query) {
            setCheckpoints(
              data.filter((x: any) =>
                x.label.toLowerCase().includes(query.toLowerCase())
              )
            );
          } else {
            setCheckpoints(data);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function searchMercados(event: any) {
    await loadMercados(event.query);
  }
  async function loadMercados(query = "") {
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/danios-checkpoint/mercado",
          {
            params: {
              codPais: securityFilters.securityValues.country.value,
            },
          }
        );
        if (status === 200) {
          if (query) {
            setMercados(
              data.filter((x: any) =>
                x.label.toLowerCase().includes(query.toLowerCase())
              )
            );
          } else {
            setMercados(data);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function searchOrigen(event: any) {
    await loadOrigen(event.query);
  }
  async function loadOrigen(query = "") {
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/danios-checkpoint/origen",
          {
            params: {
              codPais: securityFilters.securityValues.country.value,
            },
          }
        );
        if (status === 200) {
          if (query) {
            setOrigenes(
              data.filter((x: any) =>
                x.label.toLowerCase().includes(query.toLowerCase())
              )
            );
          } else {
            setOrigenes(data);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function searchMarcas(event: any) {
    await loadMarcas(event.query);
  }
  async function loadMarcas(query = "") {
    try {
      if (
        securityFilters.securityValues.country &&
        securityFilters.securityValues.client
      ) {
        const { status, data } = await sipcoAxiosService.get(
          "/danios-checkpoint/marcas",
          {
            params: {
              codPais: securityFilters.securityValues.country.value,
              codCliente: securityFilters.securityValues.client.value,
            },
          }
        );
        if (status === 200) {
          if (query) {
            setMarcas(
              data.filter((x: any) =>
                x.label.toLowerCase().includes(query.toLowerCase())
              )
            );
          } else {
            setMarcas(data);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function searchTransportista(event: any) {
    await loadTransportista(event.query);
  }
  async function loadTransportista(query = "") {
    try {
      if (
        securityFilters.securityValues.country &&
        securityFilters.securityValues.account
      ) {
        const { status, data } = await sipcoAxiosService.get(
          "/danios-checkpoint/provedor",
          {
            params: {
              codPais: securityFilters.securityValues.country.value,
              codCuenta: securityFilters.securityValues.account.value,
            },
          }
        );
        if (status === 200) {
          if (query) {
            setTransportistas(
              data.filter((x: any) =>
                x.label.toLowerCase().includes(query.toLowerCase())
              )
            );
          } else {
            setTransportistas(data);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function searchPais(event: any) {
    await loadPais(event.query);
  }
  async function loadPais(query = "") {
    try {
      const { status, data } = await sipcoAxiosService.get(
        "/danios-checkpoint/pais",
        {
          params: {
            description: query,
          },
        }
      );
      if (status === 200) {
        if (query) {
          setPaises(
            data.filter((x: any) =>
              x.descripcionFull.toLowerCase().includes(query.toLowerCase())
            )
          );
        } else {
          setPaises(data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function searchFamilias(event: any) {
    await loadFamilias(event.query);
  }
  async function loadFamilias(query = "") {
    try {
      if (
        filter.marcas.length === 1 &&
        securityFilters.securityValues.country &&
        securityFilters.securityValues.client
      ) {
        const selectedMarca = filter.marcas[0];
        const { status, data } = await sipcoAxiosService.get(
          "/danios-checkpoint/familias",
          {
            params: {
              codPais: securityFilters.securityValues.country.value,
              codCliente: securityFilters.securityValues.client.value,
              codMarca: selectedMarca.id,
            },
          }
        );
        if (status === 200) {
          if (query) {
            setFamilias(
              data.filter((x: any) =>
                x.label.toLowerCase().includes(query.toLowerCase())
              )
            );
          } else {
            setFamilias(data);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function loadReportData() {
    try {
      setLoading(true);
      const { status, data } = await sipcoAxiosService.post(
        "danios-checkpoint/report",
        {
          codPais: securityFilters?.securityValues?.country?.value || null,
          codCliente: securityFilters?.securityValues?.client?.value || "",
          clienteDescripcion:
            securityFilters?.securityValues?.client?.description || "",
          codCuenta: securityFilters?.securityValues?.account?.value || null,
          checkpoint: filter?.checkpoint?.id || null,
          consultaPorCheckPoint: filter.consultaPorCheckpoint,
          rangeFecha: filter?.fecha || [],
          mercado: filter?.mercados?.map((x) => x.id) || [],
          origens: filter?.origenes?.map((x) => x.id) || [],
          marcas: filter?.marcas?.map((x) => x.id) || [],
          familias: filter?.familias?.map((x) => x.id) || [],
          transportista: filter?.transportista?.id || null,
          paisFiltro: filter?.pais?.id || null,
        }
      );

      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div>
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: false,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="filter-options">
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[0].id} alt="Checkpoint" />
              </label>
              <AutoComplete
                value={filter.checkpoint}
                suggestions={checkpoints}
                completeMethod={searchCheckpoints}
                onChange={(e) => setFilter({ ...filter, checkpoint: e.value })}
                field="label"
                dropdown
                disabled={!securityFilters.securityValues.account}
              />
            </div>
            <div className="RadioButtonFilter">
              <RadioButton
                checked={filter.consultaPorCheckpoint}
                onChange={(e) => {
                  setFilter({ ...filter, consultaPorCheckpoint: true });
                  setReporte([]);
                }}
                style={{ justifyContent: "center", position: "relative" }}
              />
              <label>
                <LanguageProvider
                  id={filterNames[1].label}
                  alt="Consulta de daños por checkpoint"
                />
              </label>
              <RadioButton
                checked={!filter.consultaPorCheckpoint}
                onChange={(e) => {
                  setFilter({ ...filter, consultaPorCheckpoint: false });
                  setReporte([]);
                }}
                style={{ justifyContent: "center", position: "relative" }}
              />
              <label>
                <LanguageProvider
                  id={filterNames[2].label}
                  alt="Cargar Estadisticas por CP"
                />
              </label>
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"2131"} alt="Fecha" />
              </label>
              <Calendar
                value={filter.fecha}
                selectionMode="range"
                onChange={(e) => {
                  setFilter({ ...filter, fecha: e.value });
                }}
                dateFormat="dd/mm/yy"
                hideOnRangeSelection
                numberOfMonths={2}
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[5].id} alt="Mercados" />
              </label>
              <AutoComplete
                value={filter.mercados}
                suggestions={mercados}
                completeMethod={searchMercados}
                onChange={(e) => setFilter({ ...filter, mercados: e.value })}
                field="label"
                dropdown
                disabled={!securityFilters.securityValues.country}
                multiple
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[6].id} alt="Origen" />
              </label>
              <AutoComplete
                value={filter.origenes}
                suggestions={origenes}
                completeMethod={searchOrigen}
                onChange={(e) => setFilter({ ...filter, origenes: e.value })}
                field="label"
                dropdown
                disabled={!securityFilters.securityValues.country}
                multiple
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[7].id} alt="Marca" />
              </label>
              <AutoComplete
                value={filter.marcas}
                suggestions={marcas}
                completeMethod={searchMarcas}
                onChange={(e) => {
                  setFilter({ ...filter, marcas: e.value, familias: [] });
                }}
                field="label"
                dropdown
                disabled={
                  !securityFilters.securityValues.country ||
                  !securityFilters.securityValues.client
                }
                multiple
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"10021"} alt="Familias" />
              </label>
              <AutoComplete
                value={filter.familias}
                suggestions={familias}
                completeMethod={searchFamilias}
                onChange={(e) => setFilter({ ...filter, familias: e.value })}
                field="label"
                dropdown
                disabled={
                  !securityFilters.securityValues.country ||
                  !securityFilters.securityValues.client ||
                  filter.marcas.length !== 1
                }
                multiple
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[8].id} alt="Transportista" />
              </label>
              <AutoComplete
                value={filter.transportista}
                suggestions={transportistas}
                completeMethod={searchTransportista}
                onChange={(e) =>
                  setFilter({ ...filter, transportista: e.value })
                }
                field="descripcionFull"
                dropdown
                disabled={
                  !securityFilters.securityValues.country ||
                  !securityFilters.securityValues.account ||
                  filter.consultaPorCheckpoint
                }
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[9].id} alt="Pais" />
              </label>
              <AutoComplete
                value={filter.pais}
                suggestions={paises}
                completeMethod={searchPais}
                onChange={(e) => setFilter({ ...filter, pais: e.value })}
                field="descripcion"
                dropdown
                disabled={filter.consultaPorCheckpoint}
              />
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>Danios X Checkpoint</span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"DanioXCheckpoint"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        {!filter.consultaPorCheckpoint ? (
          <DataTable
            size={"small"}
            showGridlines
            stripedRows
            ref={dt}
            value={data}
            paginator
            scrollHeight="flex"
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 50]}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            style={{ maxWidth: "100%" }}
            loading={loading}
            resizableColumns
            removableSort
            filterDisplay="menu"
          >
            <Column field="0" header={columnsNames[0].label} sortable filter />
            <Column field="1" header={columnsNames[1].label} sortable filter />
            <Column field="2" header={columnsNames[2].label} sortable filter />
            <Column field="3" header={columnsNames[3].label} sortable filter />
            <Column field="4" header={columnsNames[4].label} sortable filter />
            <Column field="5" header={columnsNames[5].label} sortable filter />
            <Column field="6" header={columnsNames[6].label} sortable filter />
            <Column field="7" header={columnsNames[7].label} sortable filter />
            <Column field="8" header={columnsNames[8].label} sortable filter />
            <Column field="9" header={columnsNames[9].label} sortable filter />
            <Column
              field="10"
              header={columnsNames[10].label}
              sortable
              filter
            />
            <Column
              field="11"
              header={columnsNames[11].label}
              sortable
              filter
            />
            <Column
              field="12"
              header={columnsNames[12].label}
              sortable
              filter
            />
            <Column
              field="13"
              header={columnsNames[13].label}
              sortable
              filter
            />
            <Column
              field="14"
              header={columnsNames[14].label}
              sortable
              filter
            />
            <Column
              field="15"
              header={columnsNames[15].label}
              sortable
              filter
            />
          </DataTable>
        ) : (
          <DataTable
            size={"small"}
            showGridlines
            stripedRows
            ref={dt}
            value={data}
            paginator
            scrollHeight="flex"
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 50]}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            loading={loading}
            resizableColumns
            removableSort
            filterDisplay="menu"
          >
            <Column
              field="0"
              header={columnsNames[0].label}
              sortable
              filter
              body={(rowdata) => {
                if (rowdata[0]) {
                  return rowdata[0].replace("00:00:00", "");
                }
              }}
            />
            <Column field="1" header={columnsNames[1].label} sortable filter />
            <Column field="2" header={columnsNames[2].label} sortable filter />
            <Column
              field="3"
              header={columnsNames[3].label}
              sortable
              filter
              body={(rowdata) => {
                if (rowdata[3]) {
                  return parseFloat(rowdata[3]).toPrecision(2);
                }
              }}
            />
            <Column field="4" header={columnsNames[4].label} sortable filter />
          </DataTable>
        )}
      </Panel>
    </div>
  );
}
export default DaniosCheckpoint;
