import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { AutoComplete } from "primereact/autocomplete";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { MultiSelect } from "primereact/multiselect";
import { Panel } from "primereact/panel";
import { RadioButton } from "primereact/radiobutton";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
// import { classNames } from "primereact/utils";

export function Stock() {
  const SCREEN_CODE = "con0027_stock";
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();

  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);

  const filterLabelId = [
    1241, 1242, 21417, 11443, 21473, 396, 1149, 4397, 449, 10774, 2496, 369,
  ];
  const filterNames = [];
  const filterColumnsId = [
    19927, 54, 4791, 1491, 990, 991, 992, 10774, 2496, 396, 6590, 408, 491,
    13335, 10021, 31, 6590, 18202, 9387, 369, 932, 2941, 492, 13798, 12103,
    21430, 10979, 11307, 10758, 4724, 11443, 16932, 21473, 14456,
  ];
  const columnsNames = [];
  for (const element of filterLabelId) {
    filterNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Filter Labels",
      }),
    });
  }
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    65, 5583, 5044, 54, 1791, 467, 468, 449, 990, 991, 992, 10774, 2496, 369,
    396, 408, 16808, 16809, 491, 10021, 748, 749, 2, 1182, 16531, 10531, 1241,
    1242, 1149, 4397, 4791, 1491, 6590, 13335, 31, 13798, 12103, 10979, 11307,
    9387, 10758, 4724, 932, 2941, 492, 11443, 21417, 21418, 21473, 992, 6781,
    13358, 3774, 21422, 16932, 21906,
    //21412,
    19927, 14456, 21430, 18202, 291,
  ];
  for (const element of ids) {
    mensagens.push({
      id: element,
      label: LanguageProvider({ id: element, alt: "Error Columns Labels" }),
    });
  }

  const [filter, setFilter] = useState({
    stock: false,
    mostrarRazonSocialUltimaNF: false,
    estadoVehiculo: null,
    estadoRemito: [],
    conDanos: null,
    conBloqueo: null,
    playas: [],
    mercado: [],
    origens: [],
    destinos: [],
    marcas: [],
  });

  const [loading, setLoading] = useState(false);

  const [estadoVehiculo, setEstadoVehiculo] = useState([]);
  const [vehiculosConDanios, setVehiculosConDanios] = useState([]);
  const [vehiculosConBloqueo, setVehiculosConBloqueo] = useState([]);
  const [playas, setPlayas] = useState([]);
  const [mercados, setMercados] = useState([]);
  const [origens, setOrigens] = useState([]);
  const [destinos, setDestinos] = useState([]);
  const [marcas, setMarcas] = useState([]);

  const [loadingEstadosVeiculos, setLoadingEstadosVeiculos] = useState(true);
  const [loadingPlayas, setLoadingPlayas] = useState(true);
  const [loadingMercados, setLoadingMercados] = useState(true);
  const [loadingOrigens, setLoadingOrignes] = useState(true);
  const [loadingDestinos, setLoadingDestinos] = useState(true);
  const [loadingMarcas, setLoadingMarcas] = useState(true);

  async function searchEstadoVehiculo(event: any) {
    await loadEstadoVehiculo(event.query);
  }
  async function loadEstadoVehiculo(query = "") {
    setLoadingEstadosVeiculos(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/estado-vehiculo-options",
          {
            params: {
              codPais: securityFilters.securityValues.country.value,
              codCuenta: securityFilters.securityValues.account.value,
            },
          }
        );

        if (status === 200) {
          const estadoVehiculo = data.map((x: any) => {
            return { ...x, label: `${x.descripcion} - (${x.id})` };
          });

          estadoVehiculo.unshift({
            label: "TODOS",
            id: "TODOS",
            descripcion: "TODOS",
          });

          if (query) {
            const estadosFiltrados = estadoVehiculo.filter((x: any) =>
              x.label.toLowerCase().includes(query.toLowerCase())
            );
            setEstadoVehiculo(estadosFiltrados);
            return estadosFiltrados;
          } else {
            setEstadoVehiculo(estadoVehiculo);
            return estadoVehiculo;
          }
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingEstadosVeiculos(false);
    }
    return [];
  }

  async function searchVehiculosConDanios(event: any) {
    await loadVehiculosConDanios(event.query);
  }
  async function loadVehiculosConDanios(query = "") {
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/codigo-playa-options",
          {
            params: {
              codPais: securityFilters.securityValues.country.value,
            },
          }
        );

        if (status === 200) {
          const vehiculosConDanio = data.map((x: any) => {
            return { ...x, label: `${x.id} - ${x.descripcion}` };
          });

          if (query) {
            const conDanioFiltrados = vehiculosConDanio.filter((x: any) =>
              x.label.toLowerCase().includes(query.toLowerCase())
            );
            setVehiculosConDanios(conDanioFiltrados);
            return conDanioFiltrados;
          } else {
            setVehiculosConDanios(vehiculosConDanio);
            return vehiculosConDanio;
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function searchVehiculosConBloqueo(event: any) {
    await loadVehiculosConBloqueo(event.query);
  }
  async function loadVehiculosConBloqueo(query = "") {
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/codigo-playa-options",
          {
            params: {
              codPais: securityFilters.securityValues.country.value,
            },
          }
        );

        if (status === 200) {
          const vehiculosConBloqueo = data.map((x: any) => {
            return { ...x, label: `${x.id} - ${x.descripcion}` };
          });
          if (query) {
            const conBloqueoFiltrados = vehiculosConBloqueo.filter((x: any) =>
              x.label.toLowerCase().includes(query.toLowerCase())
            );
            setVehiculosConBloqueo(conBloqueoFiltrados);
            return conBloqueoFiltrados;
          } else {
            setVehiculosConBloqueo(vehiculosConBloqueo);
            return vehiculosConBloqueo;
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadPlaya() {
    setLoadingPlayas(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/playas-options",
          {
            params: {
              codCuenta: securityFilters.securityValues.account.value,
              descripcion: "",
            },
          }
        );

        if (status === 200) {
          setPlayas(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingPlayas(false);
    }
    return [];
  }

  async function loadMercado() {
    setLoadingMercados(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/mercados-options",
          {
            params: {
              codPais: securityFilters.securityValues.country.value,
              descripcion: "",
            },
          }
        );
        if (status === 200) {
          setMercados(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMercados(false);
    }
    return [];
  }

  async function loadOrigen() {
    setLoadingOrignes(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/origen-options",
          {
            params: {
              codPais: securityFilters.securityValues.country.value,
              descripcion: "",
            },
          }
        );

        if (status === 200) {
          setOrigens(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOrignes(false);
    }
    return [];
  }

  async function loadDestinos() {
    setLoadingDestinos(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/destinos-options",
          {
            params: {
              codPais: securityFilters.securityValues.country.value,
              descripcion: "",
            },
          }
        );

        if (status === 200) {
          const destino = data.map((x: any) => {
            return { ...x, label: `${x.descripcion} - (${x.codMarca})` };
          });
          setDestinos(destino);
          return destino;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDestinos(false);
    }
  }

  async function loadMarcas() {
    setLoadingMarcas(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/stock-marcas-options",
          {
            params: {
              codPais: securityFilters.securityValues.country.value,
              codCliente: securityFilters.securityValues.client.value,
            },
          }
        );

        if (status === 200) {
          const marcas = data.map((x: any) => {
            return { ...x, label: `${x.label} - (${x.id})` };
          });
          setMarcas(marcas);
          return marcas;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMarcas(false);
    }
    return [];
  }

  useEffect(() => {
    async function initialize() {
      if (securityFilters?.securityValues?.country != null) {
        try {
          const estadosVeiculos = await loadEstadoVehiculo();
          const estadosVeiculosDanos = await loadVehiculosConDanios();
          const estadosVeiculosBoqueios = await loadVehiculosConBloqueo();
          const playas = await loadPlaya();
          const mercados = await loadMercado();
          const origens = await loadOrigen();
          const destinos = await loadDestinos();
          const marcas = await loadMarcas();

          setFilter({
            ...filter,
            estadoVehiculo: estadosVeiculos[0],
            conDanos: estadosVeiculosDanos[0],
            conBloqueo: estadosVeiculosBoqueios[0],
            playas: playas,
            mercado: mercados,
            origens: origens,
            destinos: destinos,
            marcas: marcas,
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
  }, [securityFilters.securityValues]);

  async function loadReportData() {
    try {
      setLoading(true);
      const filters = {
        codPais: securityFilters?.securityValues?.country?.value || null,
        codCliente: securityFilters?.securityValues?.client?.value || "",
        clienteDescripcion:
          securityFilters?.securityValues?.client?.description || "",
        codCuenta: securityFilters?.securityValues?.account?.value || null,

        stock: filter.stock ? "Stock con Remitos" : "Stock Actual",
        mostrarRazonSocialUltimaNF: filter.mostrarRazonSocialUltimaNF,
        estadoVehiculo: filter?.estadoVehiculo?.id || null,
        estadoRemito: filter?.estadoRemito?.map((x) => x.codEstadoRemito) || [],
        conDanos: filter?.conDanos?.id || null,
        conBloqueo: filter?.conBloqueo?.id || null,
        playas: filter?.playas?.map((x) => x.codSubcuenta.toString()) || [],
        mercado: filter?.mercado?.map((x) => x.codMercado) || [],
        origens: filter?.origens?.map((x) => x.codOrigen) || [],
        destinos: filter?.destinos?.map((x) => x.codMarca) || [],
        marcas: filter?.marcas?.map((x) => x.id) || [],
      };
      const { status, data } = await sipcoAxiosService.post(
        "/report/stock",
        filters
      );
      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const clientIsTodos =
    securityFilters.securityValues?.client?.description === "TODOS";

  return (
    <div>
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: false,
          allClientsOptions: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="filter-options">
            <div className="RadioButtonFilter">
              <RadioButton
                checked={filter.stock}
                onChange={(e) => {
                  setFilter({ ...filter, stock: true });
                  setReporte([]);
                }}
                style={{ justifyContent: "center", position: "relative" }}
              />
              <label>
                <LanguageProvider id={filterNames[0].label} />
              </label>
              <RadioButton
                checked={!filter.stock}
                onChange={(e) => {
                  setFilter({ ...filter, stock: false });
                  setReporte([]);
                }}
                style={{ justifyContent: "center", position: "relative" }}
              />
              <label>
                <LanguageProvider id={filterNames[1].label} />
              </label>
            </div>

            <div className={"sipco-option"}>
              <label>
                <LanguageProvider
                  id={filterNames[3].id}
                  alt="Estado vehículo"
                />
              </label>
              <AutoComplete
                value={filter.estadoVehiculo}
                suggestions={estadoVehiculo}
                completeMethod={searchEstadoVehiculo}
                onChange={(e) =>
                  setFilter({ ...filter, estadoVehiculo: e.value })
                }
                field="label"
                dropdown
                forceSelection
              />
            </div>

            <div className={"sipco-option"}>
              <label>
                <LanguageProvider
                  id={filterNames[6].id}
                  alt="Listar vehiculos con daños (SI/NO/AMBOS)"
                />
              </label>
              <AutoComplete
                value={filter.conDanos}
                suggestions={vehiculosConDanios}
                completeMethod={searchVehiculosConDanios}
                onChange={(e) => setFilter({ ...filter, conDanos: e.value })}
                field="label"
                dropdown
                forceSelection
              />
            </div>

            <div className={"sipco-option"}>
              <label>
                <LanguageProvider
                  id={filterNames[7].id}
                  alt="Listar vehiculos con Bloqueo (SI/NO/AMBOS)"
                />
              </label>
              <AutoComplete
                value={filter.conBloqueo}
                suggestions={vehiculosConBloqueo}
                completeMethod={searchVehiculosConBloqueo}
                onChange={(e) => setFilter({ ...filter, conBloqueo: e.value })}
                field="label"
                dropdown
                forceSelection
              />
            </div>

            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[8].id} alt="Playas" />
              </label>
              <MultiSelect
                value={filter.playas}
                options={playas}
                onChange={(e) => {
                  setFilter({ ...filter, playas: e.value });
                }}
                display="chip"
                filter
                optionLabel="descripcion"
                loading={loadingPlayas}
                showClear
              />
            </div>

            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[9].id} alt="Mercados" />
              </label>
              <MultiSelect
                value={filter.mercado}
                options={mercados}
                onChange={(e) => {
                  setFilter({ ...filter, mercado: e.value });
                }}
                display="chip"
                filter
                optionLabel="descripcion"
                loading={loadingMercados}
                showClear
              />
            </div>

            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[10].id} alt="Origen" />
              </label>
              <MultiSelect
                value={filter.origens}
                options={origens}
                onChange={(e) => {
                  setFilter({ ...filter, origens: e.value });
                }}
                display="chip"
                filter
                optionLabel="descripcion"
                loading={loadingOrigens}
                showClear
              />
            </div>

            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[11].id} alt="Destinos" />
              </label>
              <MultiSelect
                value={filter.destinos}
                options={destinos}
                onChange={(e) => {
                  setFilter({ ...filter, destinos: e.value });
                }}
                display="chip"
                filter
                optionLabel="descripcion"
                loading={loadingDestinos}
                showClear
              />
            </div>

            {!clientIsTodos && (
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={filterNames[5].id} alt="Marca" />
                </label>
                <MultiSelect
                  value={filter.marcas}
                  options={marcas}
                  onChange={(e) => {
                    setFilter({ ...filter, marcas: e.value });
                  }}
                  display="chip"
                  filter
                  loading={loadingMarcas}
                  showClear
                />
              </div>
            )}
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                {mensagens[60].label}
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"Stock"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          loading={loading}
          filterDisplay="menu"
        >
          <Column field="0" header={columnsNames[0].label} sortable filter />
          <Column field="1" header={columnsNames[1].label} sortable filter />
          <Column field="2" header={columnsNames[2].label} sortable filter />
          <Column field="3" header={columnsNames[3].label} sortable filter />
          <Column field="4" header={columnsNames[4].label} sortable filter />
          <Column field="5" header={columnsNames[5].label} sortable filter />
          <Column field="6" header={columnsNames[6].label} sortable filter />
          <Column field="7" header={columnsNames[7].label} sortable filter />
          <Column field="8" header={columnsNames[8].label} sortable filter />
          <Column field="9" header={columnsNames[9].label} sortable filter />
          <Column field="10" header={columnsNames[10].label} sortable filter />
          <Column field="11" header={columnsNames[11].label} sortable filter />
          <Column field="12" header={columnsNames[12].label} sortable filter />
          <Column field="13" header={columnsNames[13].label} sortable filter />
          <Column field="14" header={columnsNames[14].label} sortable filter />
          <Column field="15" header={columnsNames[15].label} sortable filter />
          <Column field="16" header={columnsNames[16].label} sortable filter />
          <Column field="17" header={columnsNames[17].label} sortable filter />
          <Column field="18" header={columnsNames[18].label} sortable filter />
          <Column field="19" header={columnsNames[19].label} sortable filter />
          <Column field="20" header={columnsNames[20].label} sortable filter />
          <Column field="21" header={columnsNames[21].label} sortable filter />
          <Column field="22" header={columnsNames[22].label} sortable filter />
          <Column field="23" header={columnsNames[23].label} sortable filter />
          <Column field="24" header={columnsNames[24].label} sortable filter />
          <Column field="25" header={columnsNames[25].label} sortable filter />
          <Column field="26" header={columnsNames[26].label} sortable filter />
          <Column field="27" header={columnsNames[27].label} sortable filter />
          <Column field="28" header={columnsNames[28].label} sortable filter />
          <Column field="29" header={columnsNames[29].label} sortable filter />
          <Column field="30" header={columnsNames[30].label} sortable filter />
          <Column field="31" header={columnsNames[31].label} sortable filter />
          <Column field="32" header={columnsNames[32].label} sortable filter />
          <Column field="33" header={columnsNames[33].label} sortable filter />
        </DataTable>
      </Panel>
    </div>
  );
}
export default Stock;
