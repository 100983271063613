import React, { useRef, useState } from "react";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import usePageViews from "../../../hooks/usePageViews";

export function ListadoOperacionesRealizadas() {
  const SCREEN_CODE = "con0041_listado_operaciones_realizadas";
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);
  const columns = [];
  const filterLabelId = [2131, 55555, 1799, 683, 427, 441, 396];
  const filterNames = [];
  const filterColumnsId = [
    13324, 54, 4791, 1491, 396, 31, 396, 13514, 2139, 2140, 31, 2140, 367, 31,
    367, 3410, 31, 3410, 18356, 14456, 18308, 2765, 3625, 31, 3344, 16580, 6813,
    16789, 16790, 2765, 16791, 15739, 5366, 13706, 5271, 19690,
  ];
  const columnsNames = [];
  for (let i = 0; i < filterLabelId.length; i++) {
    filterNames.push({
      id: filterLabelId[i],
      label: LanguageProvider({
        id: filterLabelId[i],
        alt: "Error Filter Labels",
      }),
    });
  }
  for (let i = 0; i < filterColumnsId.length; i++) {
    columnsNames.push({
      id: filterColumnsId[i],
      label: LanguageProvider({
        id: filterColumnsId[i],
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    4102, 427, 1799, 754, 2765, 3625, 5366, 18308, 14456, 18356, 2396, 2140, 31,
    10531, 1131, 65, 5583, 5044, 54, 1791, 2131, 367, 2496, 396, 748, 749, 1491,
    845, 4300, 2639, 1582, 13324, 4791, 13514, 2139, 31, 3344, 16580, 6813,
    16789, 16790, 2765, 16791, 13706, 5271, 32, 1317, 467, 468, 683, 441, 408,
    5788, 3410, 15739, 19690,
  ];

  for (let i = 0; i < ids.length; i++) {
    mensagens.push({
      id: ids[i],
      label: LanguageProvider({ id: ids[i], alt: "Error Columns Labels" }),
    });
  }
  console.log(mensagens);
  return (
    <div>
      <Filter
        onSearch={setReporte}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
        filterOptions={{
          urlApi: "report/operaciones-realizadas",
          parametros: ["operacionesRealizadas"],
          customFilters: [
            { label: filterNames[0].label, variable: "Fecha", type: "date" },
            {
              label: filterNames[1].label,
              variable: "Detallado",
              type: "dropdown",
              parametros: ["codPais"],
              urlApi: "combo/detallado",
            },
            {
              label: filterNames[2].label,
              variable: "GrupoOperacion",
              type: "dropdown",
              parametros: ["codPais"],
              urlApi: "combo/grupo-operacion",
            },
            {
              label: filterNames[3].label,
              variable: "Operaciones",
              type: "multipleSelection",
              parametros: ["codPais", "grupoOperacion"],
              urlApi: "combo/operacion",
            },
            {
              label: filterNames[4].label,
              variable: "Proveedor Tractor",
              type: "multipleSelection",
              parametros: ["codPais", "codCliente"],
              urlApi: "combo/proveedor",
            },
            {
              label: filterNames[5].label,
              variable: "Playas",
              type: "multipleSelection",
              parametros: ["codPais", "codCuenta", "descripcion"],
              urlApi: "subcuentas-options",
            },
            {
              label: filterNames[6].label,
              variable: "Marca",
              type: "multipleSelection",
              parametros: ["codPais", "codCliente"],
              urlApi: "combo/marca",
            },
          ],
        }}
      />
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                Listado Operaciones Realizadas
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"Listado Operaciones Realizadas"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          filterDisplay="menu"
        >
          <Column field="0" header={columnsNames[0].label} sortable filter />
          <Column field="1" header={columnsNames[1].label} sortable filter />
          <Column field="2" header={columnsNames[2].label} sortable filter />
          <Column field="3" header={columnsNames[3].label} sortable filter />
          <Column field="4" header={columnsNames[4].label} sortable filter />
          <Column field="5" header={columnsNames[5].label} sortable filter />
          <Column field="6" header={columnsNames[6].label} sortable filter />
          <Column field="7" header={columnsNames[7].label} sortable filter />
          <Column field="8" header={columnsNames[8].label} sortable filter />
          <Column field="9" header={columnsNames[9].label} sortable filter />
          <Column field="10" header={columnsNames[10].label} sortable filter />
          <Column field="11" header={columnsNames[11].label} sortable filter />
          <Column field="12" header={columnsNames[12].label} sortable filter />
          <Column field="13" header={columnsNames[13].label} sortable filter />
          <Column field="14" header={columnsNames[14].label} sortable filter />
          <Column field="15" header={columnsNames[15].label} sortable filter />
          <Column field="16" header={columnsNames[16].label} sortable filter />
          <Column field="17" header={columnsNames[17].label} sortable filter />
          <Column field="18" header={columnsNames[18].label} sortable filter />
          <Column field="19" header={columnsNames[19].label} sortable filter />
          <Column field="20" header={columnsNames[20].label} sortable filter />
          <Column field="21" header={columnsNames[21].label} sortable filter />
          <Column field="22" header={columnsNames[22].label} sortable filter />
          <Column field="23" header={columnsNames[23].label} sortable filter />
          <Column field="24" header={columnsNames[24].label} sortable filter />
          <Column field="25" header={columnsNames[25].label} sortable filter />
          <Column field="26" header={columnsNames[26].label} sortable filter />
          <Column field="27" header={columnsNames[27].label} sortable filter />
          <Column field="28" header={columnsNames[28].label} sortable filter />
          <Column field="29" header={columnsNames[29].label} sortable filter />
        </DataTable>
      </Panel>
    </div>
  );
}
export default ListadoOperacionesRealizadas;
