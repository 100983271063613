import React, { useRef, useState } from "react";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import usePageViews from "../../../hooks/usePageViews";

export function ConsultaTranslado() {
  const SCREEN_CODE = "con0065_consulta_traslados";
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);
  const filterLabelId = [4392, 901, 16120, 16096];
  const filterNames = [];
  const filterColumnsId = [54, 2892, 17088, 17088, 1269, 2139, 367, 2496];
  const columnsNames = [];
  for (let i = 0; i < filterLabelId.length; i++) {
    filterNames.push({
      id: filterLabelId[i],
      label: LanguageProvider({
        id: filterLabelId[i],
        alt: "Error Filter Labels",
      }),
    });
  }
  for (let i = 0; i < filterColumnsId.length; i++) {
    columnsNames.push({
      id: filterColumnsId[i],
      label: LanguageProvider({
        id: filterColumnsId[i],
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    13, 27, 31, 32, 34, 39, 54, 65, 187, 321, 326, 367, 396, 491, 653, 660, 739,
    740, 741, 743, 748, 749, 753, 754, 849, 883, 901, 924, 990, 991, 992, 1024,
    1210, 1269, 1316, 1480, 1491, 1492, 1517, 1533, 1595, 1791, 2139, 2140,
    2496, 2561, 2724, 2785, 2805, 2892, 2898, 2939, 2941, 3141, 3309, 3625,
    3754, 4284, 4285, 4655, 4791, 5044, 5417, 5435, 5618, 5619, 5686, 6590,
    8296, 8596, 9215, 9306, 9314, 10034, 10269, 10270, 10746, 10758, 10767,
    10772, 10776, 10793, 10794, 10859, 10879, 10880, 10883, 10884, 10885, 10886,
    10887, 10888, 10889, 10890, 10891, 10892, 10893, 10894, 10895, 10896, 10897,
    10898, 10899, 10900, 10902, 10903, 10904, 10905, 10909, 10910, 10914, 10915,
    10958, 10959, 11047, 11142, 11143, 11144, 11234, 11328, 14282, 14722, 15596,
    16050, 16051, 16053, 16054, 16096, 16120, 16121, 16136, 16137, 16164, 16186,
    16187, 16188, 16844, 16846, 17088, 2502, 17577, 16144, 16145, 4392,
  ];
  for (let i = 0; i < ids.length; i++) {
    mensagens.push({
      id: ids[i],
      label: LanguageProvider({ id: ids[i], alt: "Error Columns Labels" }),
    });
  }
  console.log(mensagens);
  return (
    <div>
      <Filter
        onSearch={setReporte}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
        filterOptions={{
          urlApi: "/listado-transferencia/report",
          parametros: ["translado"],
          customFilters: [
            {
              label: filterNames[0].label,
              variable: "SubcuentaDestino",
              type: "dropdown",
              parametros: ["codCuenta", "descripcion"],
              urlApi: "/subcuentas-options",
              descripcionAddCode: true,
            },
            {
              label: filterNames[1].label,
              variable: "VIN",
              type: "dropdown",
              parametros: ["codPais", "codCliente", "codCuenta", "VIN"],
              urlApi: "listado-transferencia/combo/vin",
            },
            {
              label: filterNames[2].label,
              variable: "Stock",
              type: "checkbox",
            },
            {
              label: filterNames[3].label,
              variable: "Perfil",
              type: "dropdown",
              parametros: ["codPais", "codCliente", "codCuenta"],
              urlApi: "listado-transferencia/combo/perfil",
            },
          ],
        }}
      />
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>Vale Repuestos</span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"Vale Repuestos"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          style={{ maxWidth: "100%" }}
          resizableColumns
          removableSort
          filterDisplay="menu"
        >
          <Column field="0" header={columnsNames[0].label} sortable filter />
          <Column field="1" header={columnsNames[1].label} sortable filter />
          <Column field="2" header={columnsNames[2].label} sortable filter />
          <Column field="3" header={columnsNames[3].label} sortable filter />
          <Column field="4" header={columnsNames[4].label} sortable filter />
          <Column field="5" header={columnsNames[5].label} sortable filter />
          <Column field="6" header={columnsNames[6].label} sortable filter />
          <Column field="7" header={columnsNames[7].label} sortable filter />
          {/*<Column field="" header={columnsNames[8].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[9].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[10].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[11].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[12].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[13].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[14].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[15].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[16].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[17].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[18].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[19].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[20].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[21].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[22].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[23].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[24].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[25].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[26].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[27].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[28].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[29].label} sortable filter/>*/}
        </DataTable>
      </Panel>
    </div>
  );
}
export default ConsultaTranslado;
