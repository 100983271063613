import React, { useRef, useState } from "react";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import usePageViews from "../../../hooks/usePageViews";

export function ConsultaOperacionesCanceladasWeb() {
  const SCREEN_CODE = "con0092_consulta_operaciones_canceladas_web";
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);
  const filterLabelId = [1491, 18309, 1569, 15739, 19265];
  const filterNames = [];
  const filterColumnsId = [54, 19179, 18637, 17088, 15739, 19265, 11986, 5271];
  const columnsNames = [];
  for (let i = 0; i < filterLabelId.length; i++) {
    filterNames.push({
      id: filterLabelId[i],
      label: LanguageProvider({
        id: filterLabelId[i],
        alt: "Error Filter Labels",
      }),
    });
  }
  for (let i = 0; i < filterColumnsId.length; i++) {
    columnsNames.push({
      id: filterColumnsId[i],
      label: LanguageProvider({
        id: filterColumnsId[i],
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    1, 13, 54, 65, 396, 448, 466, 467, 468, 653, 748, 749, 900, 901, 990, 991,
    1182, 1184, 1491, 1569, 1791, 2139, 2140, 2892, 3750, 4589, 4791, 5044,
    5271, 5583, 19179, 18637, 17088, 15739, 19265, 11986, 5271, 10034, 13706,
    14288, 16501, 13514, 18305, 367, 12251, 3410, 18202, 11507, 17131, 1492,
    3344, 10871, 2941, 10868, 10904, 18306, 18307, 748, 749, 321, 18308, 18309,
    10531, 5366, 19178, 16245, 10774, 16416, 5788, 19179, 8380, 2502, 2521,
  ];
  for (let i = 0; i < ids.length; i++) {
    mensagens.push({
      id: ids[i],
      label: LanguageProvider({ id: ids[i], alt: "Error Columns Labels" }),
    });
  }
  console.log(mensagens);
  return (
    <div>
      <Filter
        onSearch={setReporte}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          screenCode: SCREEN_CODE,
        }}
        filterOptions={{
          urlApi: "/operaciones-canceladas-web/report",
          parametros: ["operacionesCanceladasWeb"],
          customFilters: [
            {
              label: filterNames[0].label,
              variable: "VIN",
              type: "dropdown",
              options: [],
              parametros: ["codPais", "codCliente", "codCuenta", "VIN"],
              urlApi: "/operaciones-canceladas-web/combo/vin",
            },
            {
              label: filterNames[1].label,
              variable: "GrupoOperacion",
              type: "dropdown",
              parametros: ["codPais"],
              urlApi: "/operaciones-canceladas-web/combo/grupo-operacion",
            },
            {
              label: filterNames[2].label,
              variable: "Operacion",
              parametros: ["codPais", "grupoOperacion"],
              type: "multipleSelection",
              urlApi: "/operaciones-canceladas-web/combo/operacion",
              descripcionAddCode: true,
            },
            {
              label: filterNames[3].label,
              variable: "Alta",
              type: "checkboxDate",
            },
            {
              label: filterNames[4].label,
              variable: "Cancelacion",
              type: "checkboxDate",
            },
          ],
        }}
      />
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                Consulta Operaciones Canceladas Web
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"Consulta Operaciones Canceladas Web"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          style={{ maxWidth: "100%" }}
          resizableColumns
          removableSort
          filterDisplay="menu"
        >
          <Column field="0" header={columnsNames[0].label} sortable filter />
          <Column field="1" header={columnsNames[1].label} sortable filter />
          <Column field="2" header={columnsNames[2].label} sortable filter />
          <Column field="3" header={columnsNames[3].label} sortable filter />
          <Column field="4" header={columnsNames[4].label} sortable filter />
          <Column field="5" header={columnsNames[5].label} sortable filter />
          <Column field="6" header={columnsNames[6].label} sortable filter />
          <Column field="7" header={columnsNames[7].label} sortable filter />
          {/*<Column field="" header={columnsNames[8].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[9].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[10].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[11].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[12].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[13].label} sortable filter/>*/}
        </DataTable>
      </Panel>
    </div>
  );
}
export default ConsultaOperacionesCanceladasWeb;
