import React, { useRef, useState } from "react";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import usePageViews from "../../../hooks/usePageViews";

export function PedidosConfirmadosJd() {
  const SCREEN_CODE = "con0100_pedidos_confirmados_jd";
  usePageViews();
  const dt = useRef(null);
  const [PedidosConfirmadosJd, setPedidosConfirmadosJd] = useState(null);

  const data = convertDateObjects(PedidosConfirmadosJd?.filas);
  const columns = [];
  const filterLabelId = [2940, 4170];
  const filterNames = [];
  const filterColumnsId = [
    924,
    3344,
    1491,
    6781,
    13358,
    3184,
    4170,
    25029,
    25030,
    25031,
    ,
    25032,
    25033,
    25034,
    25035,
    25036,
    25037,
    25038,
    25039,
    25040,
    25041,
    25042,
    25045,
    25043,
    25044,
  ];
  const columnsNames = [];
  for (let i = 0; i < filterLabelId.length; i++) {
    filterNames.push({
      id: filterLabelId[i],
      label: LanguageProvider({
        id: filterLabelId[i],
        alt: "Error Filter Labels",
      }),
    });
  }
  for (let i = 0; i < filterColumnsId.length; i++) {
    columnsNames.push({
      id: filterColumnsId[i],
      label: LanguageProvider({
        id: filterColumnsId[i],
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    39, 54, 65, 159, 164, 396, 448, 504, 6781, 740, 743, 924, 1131, 1342, 1491,
    13358, 2139, 2621, 2622, 2941, 3184, 3344, 3625, 4170, 4285, 4655, 4724,
    5044, 10136, 10502, 10902, 11787, 14561, 15862, 16453, 16454, 16455, 16456,
    16457, 16458, 16459, 16460, 367, 1850, 1849, 5592, 18985, 22927, 22928,
    23020, 23097, 24859, 3206, 4145, 2940, 4170, 25029, 25030, 25031, 25032,
    25033, 25034, 25035, 25036, 25037, 25038, 25039, 25040, 25041, 25042, 25043,
    25044, 25045,
  ];
  for (let i = 0; i < ids.length; i++) {
    mensagens.push({
      id: ids[i],
      label: LanguageProvider({ id: ids[i], alt: "Error Columns Labels" }),
    });
  }
  console.log(mensagens);
  return (
    <div>
      <Filter
        onSearch={setPedidosConfirmadosJd}
        securityOptions={{
          account: true,
          country: true,
          client: true,
          screenCode: SCREEN_CODE,
        }}
        filterOptions={{
          urlApi: "report/pedidos-confirmados-jd",
          parametros: ["pedidos-confirmado-jd"],
          customFilters: [
            { label: filterNames[0].label, variable: "nroHr", type: "text" },
            {
              label: filterNames[1].label,
              variable: "nroPedido",
              type: "text",
            },
          ],
        }}
      />
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                Consuta Interface JD - HR Confirmada
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"Consuta Interface JD - HR Confirmada"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          filterDisplay="menu"
        >
          <Column field="0" header={columnsNames[0].label} sortable filter />
          <Column field="1" header={columnsNames[1].label} sortable filter />
          <Column field="2" header={columnsNames[2].label} sortable filter />
          <Column field="3" header={columnsNames[3].label} sortable filter />
          <Column field="4" header={columnsNames[4].label} sortable filter />
          <Column field="5" header={columnsNames[5].label} sortable filter />
          <Column field="6" header={columnsNames[6].label} sortable filter />
          <Column field="7" header={columnsNames[7].label} sortable filter />
          <Column field="8" header={columnsNames[8].label} sortable filter />
          <Column field="9" header={columnsNames[9].label} sortable filter />
          <Column field="10" header={columnsNames[10].label} sortable filter />
          <Column field="11" header={columnsNames[11].label} sortable filter />
          <Column field="12" header={columnsNames[12].label} sortable filter />
          <Column field="13" header={columnsNames[13].label} sortable filter />
          <Column field="14" header={columnsNames[14].label} sortable filter />
          <Column field="15" header={columnsNames[15].label} sortable filter />
          <Column field="16" header={columnsNames[16].label} sortable filter />
          <Column field="17" header={columnsNames[17].label} sortable filter />
          <Column field="18" header={columnsNames[18].label} sortable filter />
          <Column field="19" header={columnsNames[19].label} sortable filter />
          <Column field="20" header={columnsNames[20].label} sortable filter />
          <Column field="21" header={columnsNames[21].label} sortable filter />
          <Column field="22" header={columnsNames[22].label} sortable filter />
          <Column field="23" header={columnsNames[23].label} sortable filter />
          <Column field="24" header={columnsNames[24].label} sortable filter />
        </DataTable>
      </Panel>
    </div>
  );
}
export default PedidosConfirmadosJd;
