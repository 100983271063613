import React, { useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { AutoComplete } from "primereact/autocomplete";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { InputText } from "primereact/inputtext";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function TransfOrd() {
  const SCREEN_CODE = "con0078_transf_ord";
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const columns = [];
  const toast = useRef<Toast>(null);

  const [modal, setModal] = useState(false);

  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);

  const [loading, setLoading] = useState(false);
  const [estados, setEstados] = useState([]);
  const [mercados, setMercados] = useState([]);
  const [tipo, setTipo] = useState([]);
  const [tipoTO, setTipoTO] = useState([]);
  const [estadosHojaRuta, setEstadosHojaRuta] = useState([]);

  const [filter, setFilter] = useState({
    fecha: [],
    estados: [],
    mercados: [],
    tipo: [],
    vin: "",
    transportOrder: "",
    tipoTo: [],
    estadoHojaRuta: []
  });

  const filterLabelId = [467, 468, 1151, 10774, 1617, 25142, 25138];
  const filterNames = [];
  const filterColumnsId = [
    25143, 25144, 54, 25145, 25146, 25147, 19265, 25148, 25149, 25150, 25151,
    25152, 25153, 11847, 13, 24864, 25154, 25169, 13889, 15818, 14712, 25155,
    25156, 25157, 25158, 12631, 25159, 25160, 25161, 25162, 25163, 25164, 25165,
    25166, 25167, 25168, 25175, 25176, 25177, 25178, 266, 25110, 921, 13097, 159, 164, 3186, 10502, 16305,
    25170, 367, 1123, 1125, 1127, 1129, 4197, 1342, 10577, 25171, 848, 16288,
    4976, 1446, 25172, 2350, 17355, 3241,
  ];

  const columnsNames = [];
  for (let i = 0; i < filterLabelId.length; i++) {
    filterNames.push({
      id: filterLabelId[i],
      label: LanguageProvider({
        id: filterLabelId[i],
        alt: "Error Filter Labels",
      }),
    });
  }
  for (let i = 0; i < filterColumnsId.length; i++) {
    columnsNames.push({
      id: filterColumnsId[i],
      label: LanguageProvider({
        id: filterColumnsId[i],
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    42, 2502, 18718, 10938, 5406, 11939, 10860, 5407, 54, 65, 467, 468, 653,
    901, 924, 1342, 1791, 2526, 3344, 4285, 4655, 4791, 5044, 5431, 7795, 10136,
    10902, 11301, 11787, 16453, 16455, 16456, 16774, 273, 10938, 5406, 11939,
    10860, 5407, 6697, 10710, 36, 39, 54, 25175, 25176, 25177, 25178, 266, 396, 504, 653, 654, 655, 662,
    740, 753, 754, 829, 870, 990, 991, 1269, 1342, 1491, 1500, 2139, 2140, 2941,
    3069, 3141, 3184, 3245, 3246, 3344, 3774, 4284, 4285, 4399, 4724, 4976,
    5271, 5394, 5536, 5701, 7645, 10043, 10044, 10344, 10711, 10712, 10718,
    10723, 10740, 10761, 10942, 10943, 10951, 11049, 11057, 11058, 11059, 11060,
    11061, 11142, 11143, 11144, 11296, 11298, 11302, 11307, 11352, 13784, 16239,
    16368, 16456, 16457, 16459, 16460, 16988, 17038, 17134, 5405, 1489, 10858,
    273, 11048, 17743, 12402, 21377, 21384, 21468, 21490, 22638, 22849, 18303,
  ];
  for (let i = 0; i < ids.length; i++) {
    mensagens.push({
      id: ids[i],
      label: LanguageProvider({ id: ids[i], alt: "Error Columns Labels" }),
    });
  }

  async function searchMercados(event: any) {
    await loadMercados(event.query);
  }
  async function loadMercados(query = "") {
    try {
      if (
        securityFilters.securityValues.country.value &&
        securityFilters.securityValues.country.value !== null
      ) {
        const { status, data } = await sipcoAxiosService.get(
          "/report/mercado",
          {
            params: {
              codPais: securityFilters.securityValues.country.value,
            },
          }
        );
        if (status === 200) {
          const estadoMercado = data.map((x: any) => {
            return { ...x, label: `${x.descripcion} - (${x.id})` };
          });

          estadoMercado.unshift({
            label: "TODOS",
            id: "TODOS",
            descripcion: "TODOS",
          });
          if (query) {
            setMercados(
              estadoMercado.filter((x: any) =>
                x.label.toLowerCase().includes(query.toLowerCase())
              )
            );
          } else {
            setMercados(estadoMercado);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function searchEstados(event: any) {
    await loadEstados(event.query);
  }
  async function loadEstados(query = "") {
    try {
      if (
        securityFilters.securityValues.country.value &&
        securityFilters.securityValues.country.value !== null
      ) {
        const { status, data } = await sipcoAxiosService.get(
          "/report/estado-remito",
          {
            params: {
              codPais: securityFilters.securityValues.country.value,
            },
          }
        );
        if (status === 200) {
          const estadoRemito = data.map((x: any) => {
            return { ...x, label: `${x.descripcion} - (${x.id})` };
          });

          estadoRemito.unshift({
            label: "TODOS",
            id: "TODOS",
            descripcion: "TODOS",
          });
          if (query) {
            setEstados(
              estadoRemito.filter((x: any) =>
                x.label.toLowerCase().includes(query.toLowerCase())));
          } else {
            setEstados(estadoRemito);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function searchTransportOrder(event: any) {
    await loadTransportOrder(event.query);
  }
  async function loadTransportOrder(query = "") {
    try {
      if (
        securityFilters.securityValues.country.value &&
        securityFilters.securityValues.country.value !== null
      ) {
        const { status, data } = await sipcoAxiosService.get(
          "/report/transport-order",
          {
            params: {
              codPais: securityFilters.securityValues.country.value,
            },
          }
        );
        if (status === 200) {
          if (query) {
            setTipoTO(
              data.filter((x: any) =>
                x.label.toLowerCase().includes(query.toLowerCase())
              )
            );
          } else {
            setTipoTO(data);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function searchTipoRemito(event: any) {
    await loadTipos(event.query);
  }
  async function loadTipos(query = "") {
    try {
      if (
        securityFilters.securityValues.country.value &&
        securityFilters.securityValues.country.value !== null
      ) {
        const { status, data } = await sipcoAxiosService.get(
          "/report/tipo-remito",
          {
            params: {
              codPais: securityFilters.securityValues.country.value,
            },
          }
        );
        if (status === 200) {
          const estadoTipoRemito = data.map((x: any) => {
            return { ...x, label: `${x.descripcion} - (${x.id})` };
          });

          estadoTipoRemito.unshift({
            label: "TODOS",
            id: "TODOS",
            descripcion: "TODOS",
          });
          if (query) {
            setTipo(
              estadoTipoRemito.filter((x: any) =>
                x.label.toLowerCase().includes(query.toLowerCase())
              )
            );
          } else {
            setTipo(estadoTipoRemito);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function searchEstadoHojaRuta(event: any) {
    await loadEstadosHojaRuta(event.query);
  }
  async function loadEstadosHojaRuta(query = "") {
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "estado-hoja-ruta-options",
          {
            params: {
              codPais: securityFilters.securityValues.country.value,
            },
          }
        );

        if (status === 200) {
          const estadoH = data.map((x: any) => {
            return { ...x, label: `${x.descripcion} - (${x.id})` };
          });

          estadoH.unshift({
            label: "TODOS",
            id: "TODOS",
            descripcion: "TODOS",
          });

          if (query) {
            setEstadosHojaRuta(
              estadoH.filter((x: any) =>
                x.label.toLowerCase().includes(query.toLowerCase())
              )
            );
          } else {
            setEstadosHojaRuta(estadoH);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  const showError = (id: string, alt: string) => {
    toast.current.show({
      severity: "error",
      summary: "Erro",
      detail: <LanguageProvider id={id} alt={alt} />,
      life: 3000,
    });
  };

  function fechaNull(){
    if (filter?.fecha.length === 0) {
      return showError("25179", "Fecha Obrigatória");
    } 
  }

  async function loadReportData() {
    fechaNull();
    try {
      setLoading(true);
      const { status, data } = await sipcoAxiosService.post(
        "report/transf-ord",
        {
          codPais: securityFilters?.securityValues?.country?.value || null,
          codCuenta: securityFilters?.securityValues?.account?.value || null,
          rangeFecha: filter?.fecha,  
          estadosRemitos: filter?.estados?.map((x) => x.id) || [],
          mercado: filter?.mercados?.map((x) => x.id) || [],
          remitos: filter?.tipo?.map((x) => x.id) || [],
          vin: filter?.vin || "",
          transportOrder: filter?.transportOrder || "",
          tipoTransportOrder: filter?.tipoTo.map((x) => x.id) || [],
          estadoHojaRuta: filter?.estadoHojaRuta.map((x) => x.id) || [],
        }
      );
      if (status === 200) { 
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }
  return (
    <div>
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: false,
          country: true,
          subaccount: false,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="filter-options">
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider
                  id={filterNames[0].label + filterNames[1].label}
                  alt="Fecha"
                />
              </label>
              <Calendar
                value={filter.fecha}
                selectionMode="range"
                onChange={(e) => setFilter({ ...filter, fecha: e.value })}
                dateFormat="dd/mm/yy"
                hideOnRangeSelection
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider
                  id={filterNames[2].label}
                  alt="Estado Remito"
                />
              </label>
              <AutoComplete
                value={filter.estados}
                suggestions={estados}
                completeMethod={searchEstados}
                field="label"
                onChange={(e) =>
                  setFilter({ ...filter, estados: e.target.value })
                }
                dropdown
                multiple
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[3].label} alt="Mercado" />
              </label>
              <AutoComplete
                value={filter.mercados}
                suggestions={mercados}
                completeMethod={searchMercados}
                field="label"
                onChange={(e) =>
                  setFilter({ ...filter, mercados: e.target.value })
                }
                disabled={!securityFilters.securityValues.country}
                dropdown
                multiple
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[3].label} alt="Tipo" />
              </label>
              <AutoComplete
                value={filter.tipo}
                suggestions={tipo}
                completeMethod={searchTipoRemito}
                field="label"
                onChange={(e) => setFilter({ ...filter, tipo: e.target.value })}
                disabled={!securityFilters.securityValues.country}
                dropdown
                multiple
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider  alt="Estado Hoja Ruta" />
              </label>
              <AutoComplete
                value={filter.estadoHojaRuta}
                suggestions={estadosHojaRuta}
                completeMethod={searchEstadoHojaRuta}
                field="label"
                onChange={(e) => setFilter({ ...filter, estadoHojaRuta: e.target.value })}
                disabled={!securityFilters.securityValues.country}
                dropdown
                multiple
              />
            </div>
          </div>
        </AccordionTab>
        <AccordionTab
          header={<LanguageProvider alt="Filtros Transport Order" />}
        >
          <div className="filter-options">
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={17088} alt="Vin" />
              </label>
              <InputText
                value={filter.vin}
                onChange={(e) => setFilter({ ...filter, vin: e.target.value })}
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={25173} alt="Número Transport Order" />
              </label>
              <InputText
                value={filter.transportOrder}
                onChange={(e) =>
                  setFilter({ ...filter, transportOrder: e.target.value })
                }
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={25174} alt="Tipo Transport Order" />
              </label>
              <AutoComplete
                value={filter.tipoTo}
                suggestions={tipoTO}
                completeMethod={searchTransportOrder}
                field="label"
                onChange={(e) =>
                  setFilter({ ...filter, tipoTo: e.target.value })
                }
                disabled={!securityFilters.securityValues.country}
                dropdown
                multiple
              />
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                Consulta Transport Order
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"Consulta Transport Order"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          loading={loading}
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          filterDisplay="menu"
        >
          <Column field="0" header={columnsNames[0].label} sortable filter />
          <Column field="1" header={columnsNames[1].label} sortable filter />
          <Column field="2" header={columnsNames[2].label} sortable filter />
          <Column field="3" header={columnsNames[3].label} sortable filter />
          <Column field="4" header={columnsNames[4].label} sortable filter />
          <Column field="5" header={columnsNames[5].label} sortable filter />
          <Column field="6" header={columnsNames[6].label} sortable filter />
          <Column field="7" header={columnsNames[7].label} sortable filter />
          <Column field="8" header={columnsNames[8].label} sortable filter />
          <Column field="9" header={columnsNames[9].label} sortable filter />
          <Column field="10" header={columnsNames[10].label} sortable filter />
          <Column field="11" header={columnsNames[11].label} sortable filter />
          <Column field="12" header={columnsNames[12].label} sortable filter />
          <Column field="13" header={columnsNames[13].label} sortable filter />
          <Column field="14" header={columnsNames[14].label} sortable filter />
          <Column field="15" header={columnsNames[15].label} sortable filter />
          <Column field="16" header={columnsNames[16].label} sortable filter />
          <Column field="17" header={columnsNames[17].label} sortable filter />
          <Column field="18" header={columnsNames[18].label} sortable filter />
          <Column field="19" header={columnsNames[19].label} sortable filter />
          <Column field="20" header={columnsNames[20].label} sortable filter />
          <Column field="21" header={columnsNames[21].label} sortable filter />
          <Column field="22" header={columnsNames[22].label} sortable filter />
          <Column field="23" header={columnsNames[23].label} sortable filter />
          <Column field="24" header={columnsNames[24].label} sortable filter />
          <Column field="25" header={columnsNames[25].label} sortable filter />
          <Column field="26" header={columnsNames[26].label} sortable filter />
          <Column field="27" header={columnsNames[27].label} sortable filter />
          <Column field="28" header={columnsNames[28].label} sortable filter />
          <Column field="29" header={columnsNames[29].label} sortable filter />

          <Column field="30" header={columnsNames[30].label} sortable filter />
          <Column field="31" header={columnsNames[31].label} sortable filter />
          <Column field="32" header={columnsNames[32].label} sortable filter />
          <Column field="33" header={columnsNames[33].label} sortable filter />
          <Column field="34" header={columnsNames[34].label} sortable filter />
          <Column field="35" header={columnsNames[35].label} sortable filter />

          <Column field="36" header={columnsNames[36].label} sortable filter />
          <Column field="37" header={columnsNames[37].label} sortable filter />
          <Column field="38" header={columnsNames[38].label} sortable filter />
          <Column field="39" header={columnsNames[39].label} sortable filter />
          <Column field="40" header={columnsNames[40].label} sortable filter />
          

          <Column field="41" header={columnsNames[41].label} sortable filter />
          <Column field="42" header={columnsNames[42].label} sortable filter />
          <Column field="43" header={columnsNames[43].label} sortable filter />
          <Column field="44" header={columnsNames[44].label} sortable filter />

          <Column field="45" header={columnsNames[45].label} sortable filter />
          <Column field="46" header={columnsNames[46].label} sortable filter />
          <Column field="47" header={columnsNames[47].label} sortable filter />
          <Column field="48" header={columnsNames[48].label} sortable filter />
          <Column field="49" header={columnsNames[49].label} sortable filter />
          <Column field="50" header={columnsNames[50].label} sortable filter />
          <Column field="51" header={columnsNames[51].label} sortable filter />
          <Column field="52" header={columnsNames[52].label} sortable filter />
          <Column field="53" header={columnsNames[53].label} sortable filter />
          <Column field="54" header={columnsNames[54].label} sortable filter />

          <Column field="55" header={columnsNames[55].label} sortable filter />
          <Column field="56" header={columnsNames[56].label} sortable filter />
          <Column field="57" header={columnsNames[57].label} sortable filter />
          <Column field="58" header={columnsNames[58].label} sortable filter />
          <Column field="59" header={columnsNames[59].label} sortable filter />
          <Column field="60" header={columnsNames[60].label} sortable filter />
          <Column field="61" header={columnsNames[61].label} sortable filter />
          <Column field="62" header={columnsNames[62].label} sortable filter />
          <Column field="63" header={columnsNames[63].label} sortable filter />
          <Column field="64" header={columnsNames[64].label} sortable filter />

          <Column field="65" header={columnsNames[65].label} sortable filter />
          <Column field="66" header={columnsNames[66].label} sortable filter />
        </DataTable>
      </Panel>
    </div>
  );
}
export default TransfOrd;
