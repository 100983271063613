import React, { useRef, useState } from "react";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import usePageViews from "../../../hooks/usePageViews";

export function VehiculosPendientesTaller() {
  const SCREEN_CODE = "con0049_vehiculos_pendiente_taller";
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);
  const filterLabelId = [396, 901];
  const filterNames = [];
  const filterColumnsId = [
    396, 17088, 321, 2139, 17091, 17092, 5701, 9857, 3232, 9904, 2520, 1043,
    10541, 16243, 17028, 336, 3625, 16480, 2519, 16950, 2520, 19689, 2496,
    19690, 14624, 11370, 16553, 16478,
  ];
  const columnsNames = [];
  for (let i = 0; i < filterLabelId.length; i++) {
    filterNames.push({
      id: filterLabelId[i],
      label: LanguageProvider({
        id: filterLabelId[i],
        alt: "Error Filter Labels",
      }),
    });
  }
  for (let i = 0; i < filterColumnsId.length; i++) {
    columnsNames.push({
      id: filterColumnsId[i],
      label: LanguageProvider({
        id: filterColumnsId[i],
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    54, 65, 187, 321, 336, 396, 653, 654, 901, 1043, 1791, 2139, 2502, 2519,
    2520, 3232, 3625, 4791, 5044, 5583, 5701, 9857, 9904, 9905, 10541, 16243,
    16480, 16950, 17028, 17088, 17091, 17092, 2496, 14624, 11370, 16553, 16478,
    19689, 19690,
  ];
  for (let i = 0; i < ids.length; i++) {
    mensagens.push({
      id: ids[i],
      label: LanguageProvider({ id: ids[i], alt: "Error Columns Labels" }),
    });
  }
  console.log(mensagens);
  return (
    <div>
      <Filter
        onSearch={setReporte}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          allClientsOptions: true,
          screenCode: SCREEN_CODE,
        }}
        filterOptions={{
          urlApi: "vehiculos-pendientes-taller/report",
          parametros: ["vehiculosPendientesTaller"],
          customFilters: [
            {
              label: filterNames[0].label,
              variable: "Marca",
              type: "dropdown",
              parametros: ["codPais", "codCliente"],
              urlApi: "vehiculos-pendientes-taller/combo/marca",
              optionAll: true,
            },
            {
              label: filterNames[1].label,
              variable: "vin",
              type: "dropdown",
              parametros: ["codPais", "codCliente", "descripcion", "codCuenta"],
              urlApi: "vehiculos-pendientes-taller/combo/vin",
            },
          ],
        }}
      />
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                Vehiculos Pendientes Taller
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"Vehiculos Pendientes Taller"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          filterDisplay="menu"
        >
          <Column field="0" header={columnsNames[0].label} sortable filter />
          <Column field="" header={columnsNames[1].label} sortable filter />
          <Column field="" header={columnsNames[2].label} sortable filter />
          <Column field="" header={columnsNames[3].label} sortable filter />
          <Column field="" header={columnsNames[4].label} sortable filter />
          <Column field="" header={columnsNames[5].label} sortable filter />
          <Column field="" header={columnsNames[6].label} sortable filter />
          <Column field="" header={columnsNames[7].label} sortable filter />
          <Column field="" header={columnsNames[8].label} sortable filter />
          <Column field="" header={columnsNames[9].label} sortable filter />
          <Column field="" header={columnsNames[10].label} sortable filter />
          <Column field="" header={columnsNames[11].label} sortable filter />
          <Column field="" header={columnsNames[12].label} sortable filter />
          <Column field="" header={columnsNames[13].label} sortable filter />
          <Column field="" header={columnsNames[14].label} sortable filter />
          <Column field="" header={columnsNames[15].label} sortable filter />
          <Column field="" header={columnsNames[16].label} sortable filter />
          <Column field="" header={columnsNames[17].label} sortable filter />
          <Column field="" header={columnsNames[18].label} sortable filter />
          <Column field="" header={columnsNames[19].label} sortable filter />
          <Column field="" header={columnsNames[20].label} sortable filter />
          <Column field="" header={columnsNames[21].label} sortable filter />
          <Column field="" header={columnsNames[22].label} sortable filter />
          <Column field="" header={columnsNames[23].label} sortable filter />
          <Column field="" header={columnsNames[24].label} sortable filter />
          <Column field="" header={columnsNames[25].label} sortable filter />
          <Column field="" header={columnsNames[26].label} sortable filter />
          <Column field="" header={columnsNames[27].label} sortable filter />
          {/*<Column field="" header={columnsNames[28].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[29].label} sortable filter/>*/}
        </DataTable>
      </Panel>
    </div>
  );
}
export default VehiculosPendientesTaller;
