import React, { useRef, useState } from "react";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import usePageViews from "../../../hooks/usePageViews";

export function ListadoArmadoViajes() {
  const SCREEN_CODE = "con0051_listado_armado_viajes";
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);
  const filterLabelId = [
    10527, 10528, 1617, 10774, 1151, 266, 1285, 1284, 382, 16842,
  ];
  const filterNames = [];
  const filterColumnsId = [
    54, 10502, 164, 3186, 10509, 10508, 10507, 10506, 492, 1491, 932, 441, 2139,
    4179, 1284, 924, 4145, 1492, 2496,
  ];
  const columnsNames = [];
  for (let i = 0; i < filterLabelId.length; i++) {
    filterNames.push({
      id: filterLabelId[i],
      label: LanguageProvider({
        id: filterLabelId[i],
        alt: "Error Filter Labels",
      }),
    });
  }
  for (let i = 0; i < filterColumnsId.length; i++) {
    columnsNames.push({
      id: filterColumnsId[i],
      label: LanguageProvider({
        id: filterColumnsId[i],
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    10750, 6697, 54, 65, 164, 266, 382, 441, 467, 468, 530, 748, 749, 924, 932,
    1151, 1182, 1284, 1285, 1491, 1617, 1791, 2139, 2502, 3186, 4145, 4179,
    5044, 5583, 10502, 10506, 10507, 10508, 10509, 10527, 10528, 10531, 10774,
    16531, 16842, 1492, 2496, 492,
  ];
  for (let i = 0; i < ids.length; i++) {
    mensagens.push({
      id: ids[i],
      label: LanguageProvider({ id: ids[i], alt: "Error Columns Labels" }),
    });
  }
  console.log(mensagens);
  return (
    <div>
      <Filter
        onSearch={setReporte}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: false,
          allClientsOptions: true,
          screenCode: SCREEN_CODE,
        }}
        filterOptions={{
          urlApi: "listado-armado-viajes/report",
          parametros: ["listadoArmadoViajes"],
          customFilters: [
            {
              variable: "radioButton",
              type: "radioButton",
              options: [filterNames[0].label, filterNames[1].label],
            },
            {
              label: filterNames[2].label,
              variable: "TipoRemito",
              type: "multipleSelection",
              parametros: ["codPais"],
              urlApi: "listado-armado-viajes/combo/tipo-remito",
            },
            {
              label: filterNames[3].label,
              variable: "Mercados",
              type: "multipleSelection",
              options: [],
              parametros: ["codPais"],
              urlApi: "listado-armado-viajes/combo/mercado",
            },
            {
              label: filterNames[4].label,
              variable: "Estados",
              type: "multipleSelection",
              options: [],
              parametros: ["codPais"],
              urlApi: "listado-armado-viajes/combo/estado",
            },
            {
              label: filterNames[5].label,
              variable: "Concesionarios",
              type: "multipleSelection",
              parametros: ["codPais", "codCliente"],
              urlApi: "listado-armado-viajes/combo/concesionario",
            },
            {
              label: filterNames[6].label,
              variable: "DireccionEntrega",
              type: "multipleSelection",
              parametros: ["codPais", "codCliente", "codConcesionario"],
              urlApi: "listado-armado-viajes/combo/direccion-entrega",
            },
            { label: filterNames[6].label, variable: "Fecha", type: "date" },
            {
              label: filterNames[7].label,
              variable: "Liberacion",
              type: "checkboxDate",
            },
            {
              label: filterNames[8].label,
              variable: "Despacho",
              type: "checkboxDate",
            },
            {
              label: filterNames[9].label,
              variable: "CargaRetorno",
              type: "checkboxDate",
            },
          ],
        }}
      />
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                Listado de Armado de Viajes
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"Listado de Armado de Viajes"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          filterDisplay="menu"
        >
          <Column field="0" header={columnsNames[0].label} sortable filter />
          <Column field="1" header={columnsNames[1].label} sortable filter />
          <Column field="2" header={columnsNames[2].label} sortable filter />
          <Column field="3" header={columnsNames[3].label} sortable filter />
          <Column field="4" header={columnsNames[4].label} sortable filter />
          <Column field="5" header={columnsNames[5].label} sortable filter />
          <Column field="6" header={columnsNames[6].label} sortable filter />
          <Column field="7" header={columnsNames[7].label} sortable filter />
          <Column field="8" header={columnsNames[8].label} sortable filter />
          <Column field="9" header={columnsNames[9].label} sortable filter />
          <Column field="10" header={columnsNames[10].label} sortable filter />
          <Column field="11" header={columnsNames[11].label} sortable filter />
          <Column field="12" header={columnsNames[12].label} sortable filter />
          <Column field="13" header={columnsNames[13].label} sortable filter />
          <Column field="14" header={columnsNames[14].label} sortable filter />
          <Column field="15" header={columnsNames[15].label} sortable filter />
          <Column field="16" header={columnsNames[16].label} sortable filter />
          <Column field="17" header={columnsNames[17].label} sortable filter />
          <Column field="18" header={columnsNames[18].label} sortable filter />
        </DataTable>
      </Panel>
    </div>
  );
}
export default ListadoArmadoViajes;
