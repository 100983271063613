import "./App.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "primereact/resources/themes/lara-light-blue/theme.css";

import React, { createContext } from "react";

import { AuthProvider } from "@shared/AuthContext";
import NavProvider from "./nav-provider";
import { PrimeReactProvider } from "primereact/api";
import { SipcoAxiosProvider } from "@services/axios/sipco-axios-service";

export const ThemeContext = createContext();

function App() {
  return (
    <PrimeReactProvider>
      <SipcoAxiosProvider>
        <AuthProvider>
          <NavProvider />
        </AuthProvider>
      </SipcoAxiosProvider>
    </PrimeReactProvider>
  );
}

export default App;
