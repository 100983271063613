import React, { useRef, useState } from "react";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import usePageViews from "../../../hooks/usePageViews";

export function HistoriaVin() {
  const SCREEN_CODE = "con0036_historia_del_vin";
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);
  const filterLabelId = [327, 1151, 681, 3233, 3232, 3231, 467, 468];
  const filterNames = [];
  const filterColumnsId = [
    1491, 16478, 1151, 16796, 16797, 4243, 9965, 396, 2139, 4724, 2140, 1317,
    2205, 5701, 5788, 1684, 31, 16798, 2496, 4137, 4587, 17012, 3568, 4061,
    24997,
  ];
  const columnsNames = [];
  for (let i = 0; i < filterLabelId.length; i++) {
    filterNames.push({
      id: filterLabelId[i],
      label: LanguageProvider({
        id: filterLabelId[i],
        alt: "Error Filter Labels",
      }),
    });
  }
  for (let i = 0; i < filterColumnsId.length; i++) {
    columnsNames.push({
      id: filterColumnsId[i],
      label: LanguageProvider({
        id: filterColumnsId[i],
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    2, 31, 54, 65, 187, 327, 396, 467, 468, 681, 1002, 1151, 1182, 1253, 1317,
    1491, 1684, 1791, 2020, 2139, 2140, 2205, 2248, 2249, 2496, 2502, 3231,
    3232, 3233, 4137, 4243, 4587, 4724, 4791, 5044, 5583, 5701, 5788, 8818,
    9965, 10531, 16478, 16796, 16797, 16798, 17012, 3568, 4061, 24997,
  ];
  for (let i = 0; i < ids.length; i++) {
    mensagens.push({
      id: ids[i],
      label: LanguageProvider({ id: ids[i], alt: "Error Columns Labels" }),
    });
  }
  console.log(mensagens);
  return (
    <div>
      <Filter
        onSearch={setReporte}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
        filterOptions={{
          urlApi: "historia-del-vin/report",
          parametros: ["historiaDelVin"],
          customFilters: [
            {
              label: filterNames[0].label,
              variable: "Taller",
              type: "dropdown",
              options: [],
              parametros: ["codPais", "codCuenta", "codSubcuenta"],
              urlApi: "historia-del-vin/combo/taller",
            },
            {
              label: filterNames[1].label,
              variable: "Estados",
              type: "dropdown",
              options: [],
              parametros: ["codPais"],
              urlApi: "historia-del-vin/combo/estado",
              optionAll: true,
              descripcionAddCode: true,
            },
            { label: filterNames[2].label, variable: "vin", type: "text" },
            {
              label: filterNames[3].label,
              variable: "boolean",
              type: "checkbox",
            },
            {
              variable: "filtroPorFecha",
              type: "radioButton",
              options: [filterNames[4].label, filterNames[5].label],
            },
            {
              label: filterNames[6].label + filterNames[7].label,
              variable: "Fecha",
              type: "date",
            },
          ],
        }}
      />
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>Historia del VIN</span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"Historia del VIN"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
        style={{ margin: "0" }}
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          filterDisplay="menu"
        >
          <Column field="0" header={columnsNames[0].label} sortable filter />
          <Column field="1" header={columnsNames[1].label} sortable filter />
          <Column field="2" header={columnsNames[2].label} sortable filter />
          <Column field="3" header={columnsNames[3].label} sortable filter />
          <Column field="4" header={columnsNames[4].label} sortable filter />
          <Column field="5" header={columnsNames[5].label} sortable filter />
          <Column field="6" header={columnsNames[6].label} sortable filter />
          <Column field="7" header={columnsNames[7].label} sortable filter />
          <Column field="8" header={columnsNames[8].label} sortable filter />
          <Column field="9" header={columnsNames[9].label} sortable filter />
          <Column field="10" header={columnsNames[10].label} sortable filter />
          <Column field="11" header={columnsNames[11].label} sortable filter />
          <Column field="12" header={columnsNames[12].label} sortable filter />
          <Column field="13" header={columnsNames[13].label} sortable filter />
          <Column field="14" header={columnsNames[14].label} sortable filter />
          <Column field="15" header={columnsNames[15].label} sortable filter />
          <Column field="16" header={columnsNames[16].label} sortable filter />
          <Column field="17" header={columnsNames[17].label} sortable filter />
          <Column field="18" header={columnsNames[18].label} sortable filter />
          <Column field="19" header={columnsNames[19].label} sortable filter />
          <Column field="20" header={columnsNames[20].label} sortable filter />
          <Column field="21" header={columnsNames[21].label} sortable filter />
          <Column field="22" header={columnsNames[22].label} sortable filter />
          <Column field="23" header={columnsNames[23].label} sortable filter />
          <Column field="24" header={columnsNames[24].label} sortable filter />
        </DataTable>
      </Panel>
      <Panel header="Respuestos" style={{ margin: "0" }}>
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          paginator
          scrollHeight="flex"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          filterDisplay="menu"
        >
          <Column field="0" header={columnsNames[0].label} sortable filter />
          <Column field="" header={columnsNames[1].label} sortable filter />
          <Column field="" header={columnsNames[2].label} sortable filter />
          <Column field="" header={columnsNames[3].label} sortable filter />
          <Column field="" header={columnsNames[4].label} sortable filter />
          <Column field="" header={columnsNames[5].label} sortable filter />
          <Column field="" header={columnsNames[6].label} sortable filter />
          <Column field="" header={columnsNames[7].label} sortable filter />
          <Column field="" header={columnsNames[8].label} sortable filter />
          <Column field="" header={columnsNames[9].label} sortable filter />
          <Column field="" header={columnsNames[10].label} sortable filter />
          <Column field="" header={columnsNames[11].label} sortable filter />
          <Column field="" header={columnsNames[12].label} sortable filter />
          <Column field="" header={columnsNames[13].label} sortable filter />
          <Column field="" header={columnsNames[14].label} sortable filter />
          <Column field="" header={columnsNames[15].label} sortable filter />
          <Column field="" header={columnsNames[16].label} sortable filter />
          <Column field="" header={columnsNames[17].label} sortable filter />
          <Column field="" header={columnsNames[18].label} sortable filter />
          <Column field="" header={columnsNames[19].label} sortable filter />
          <Column field="" header={columnsNames[20].label} sortable filter />
          <Column field="" header={columnsNames[21].label} sortable filter />
          <Column field="" header={columnsNames[22].label} sortable filter />
          <Column field="" header={columnsNames[23].label} sortable filter />
          <Column field="" header={columnsNames[24].label} sortable filter />
          {/*<Column field="" header={columnsNames[25].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[26].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[27].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[28].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[29].label} sortable filter/>*/}
        </DataTable>
      </Panel>
    </div>
  );
}
export default HistoriaVin;
