import "./FilterSecuritySection.scss";

import {
  AutoComplete,
  AutoCompleteChangeEvent,
  AutoCompleteProps,
} from "primereact/autocomplete";
import { Dropdown, DropdownProps } from "primereact/dropdown";
import {
  DropdownValueDTO,
  ScreenSecurityFilters,
} from "./security-filter-interfaces";
import React, { ReactElement, useEffect, useRef, useState } from "react";

import { FilterSecurityOptions } from "../filter";
import { LanguageProvider } from "../../language-provider";
import useAuth from "@shared/AuthContext";
import { useFilterContext } from "../../../../protected-routes";
import { useSipcoAxiosService } from "../../../../services/axios/sipco-axios-service";

export interface FitlerSecuritySectionProps extends FilterSecurityOptions {}

export interface FilterSecurityOptionsValues {
  country: DropdownValueDTO<number>;
  client: DropdownValueDTO<string>;
  account: DropdownValueDTO<number>;
  subaccount: DropdownValueDTO<number>;
}
//TODO - provavel problema no > 0 é que informações que vem do back são muito diferentes dos apresentados no pale, exemplo de subconta
// que contem 15 sub e no pale contem 22, fora que no >0 parece vir apenas resultados com começo com letra A
export function FitlerSecuritySection(
  props: FitlerSecuritySectionProps
): ReactElement {
  const sipcoAxiosService = useSipcoAxiosService();
  const { values, setValues } = useFilterContext();
  const { user } = useAuth();

  const [securityFilters, setSecurityFilters] = useState<
    ScreenSecurityFilters[]
  >([]);

  const [countryOptions, setCountryOptions] = useState<
    DropdownValueDTO<number>[]
  >([]);
  const [clientOptions, setClientOptions] = useState<
    DropdownValueDTO<string>[]
  >([]);
  const [accountOptions, setAccountOptions] = useState<
    DropdownValueDTO<number>[]
  >([]);
  const [subaccountOptions, setSubaccountOptions] = useState<
    DropdownValueDTO<number>[]
  >([]);

  const [countryOptionsOriginal, setCountryOptionsOriginal] = useState<
    DropdownValueDTO<number>[]
  >([]);
  const [clientOptionsOriginal, setClientOptionsOriginal] = useState<
    DropdownValueDTO<string>[]
  >([]);
  const [accountOptionsOriginal, setAccountOptionsOriginal] = useState<
    DropdownValueDTO<number>[]
  >([]);
  const [subaccountOptionsOriginal, setSubaccountOptionsOriginal] = useState<
    DropdownValueDTO<number>[]
  >([]);

  const [loadingSecurityFilters, setLoadingSecurityFilters] = useState(true);

  useEffect(() => {
    if (user.nivel > 0) {
      async function loadUserPermissions() {
        try {
          setLoadingSecurityFilters(true);
          const userPermissions = await loadUserSecurityFilters(
            props.screenCode
          );
          setSecurityFilters(userPermissions);
          console.log("userPermissions", userPermissions);

          let filterInitialValues = values.securityValues;

          // Map countries
          const countries = userPermissions.map((x: ScreenSecurityFilters) => {
            return new DropdownValueDTO<number>(
              x.country.value,
              `(${x.country.value}) ${x.country.description}`
            );
          });
          setCountryOptions(countries);
          setCountryOptionsOriginal(countries);
          filterInitialValues = {
            ...filterInitialValues,
            country: countries[0],
          };

          // Map clients
          const selectedCountry = userPermissions.find(
            (x) => x.country.value === filterInitialValues.country.value
          );
          const clients = selectedCountry?.clients.map((x) => {
            return new DropdownValueDTO<string>(
              x.client.value,
              `(${x.client.value}) ${x.client.description}`
            );
          });
          setClientOptions(clients);
          setClientOptionsOriginal(clients);
          filterInitialValues = {
            ...filterInitialValues,
            client: clients[0],
          };

          // Map accounts
          const selectedClient = selectedCountry?.clients.find(
            (x) => x.client.value === filterInitialValues.client.value
          );
          const accounts = selectedClient?.accounts.map((x) => {
            return new DropdownValueDTO<number>(
              x.account.value,
              `(${x.account.value}) ${x.account.description}`
            );
          });
          setAccountOptions(accounts);
          setAccountOptionsOriginal(accounts);
          filterInitialValues = {
            ...filterInitialValues,
            account: accounts[0],
          };

          // Map subaccounts
          const selectedAccount = selectedClient?.accounts.find(
            (x) => x.account.value === filterInitialValues.account.value
          );
          const subaccounts =
            selectedAccount?.subAccounts.map((x) => {
              return new DropdownValueDTO<number>(
                x.value,
                `(${x.value}) ${x.description}`
              );
            }) || [];
          setSubaccountOptions(subaccounts);
          setSubaccountOptionsOriginal(subaccounts);
          filterInitialValues = {
            ...filterInitialValues,
            subaccount: subaccounts[0],
          };
          setValues({ ...values, securityValues: filterInitialValues });
        } catch (error) {
          console.error(error);
        } finally {
          setLoadingSecurityFilters(false);
        }
      }
      loadUserPermissions();
    }
  }, [user]);

  //chamada aqui
  async function loadUserSecurityFilters(
    screenCode: string
  ): Promise<ScreenSecurityFilters[]> {
    try {
      const response = await sipcoAxiosService.get<ScreenSecurityFilters[]>(
        "/screens/get-screen-security-permissions",
        {
          params: {
            screenCode: screenCode,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || "An error occurred");
    }
  }

  async function searchCountry(event: any) {
    await loadContries(event.query);
  }
  async function searchClient(event: any) {
    await loadClients(event.query, values.securityValues.country);
  }
  async function searchAccount(event: any) {
    await loadAccounts(event.query, values.securityValues.country);
  }
  async function searchSubaccount(event: any) {
    await loadSubAccounts(event.query, values.securityValues.account);
  }

  function searchUserCountries(event: any) {
    try {
      if (event.query || event.query === "") {
        const filteredCountries = countryOptionsOriginal.filter((x) =>
          x.description.toLowerCase().includes(event.query.toLowerCase())
        );
        if (filteredCountries.length > 0) {
          setCountryOptions(filteredCountries);
        } else {
          setCountryOptions(countryOptionsOriginal);
        }
      } else {
        setCountryOptions(countryOptionsOriginal);
      }
    } catch (error) {
      console.error(error);
    }
  }
  function searchUserClients(event: any) {
    try {
      if (event.query || event.query === "") {
        const filteredClients = clientOptionsOriginal.filter((x) =>
          x.description.toLowerCase().includes(event.query.toLowerCase())
        );
        if (filteredClients.length > 0) {
          setClientOptions(filteredClients);
        } else {
          setClientOptions(clientOptionsOriginal);
        }
      } else {
        setClientOptions(clientOptionsOriginal);
      }
    } catch (error) {
      console.error(error);
    }
  }
  function searchUserAccounts(event: any) {
    try {
      if (event.query || event.query === "") {
        const filteredAccounts = accountOptionsOriginal.filter((x) =>
          x.description.toLowerCase().includes(event.query.toLowerCase())
        );
        if (filteredAccounts.length > 0) {
          setAccountOptions(filteredAccounts);
        } else {
          setAccountOptions(accountOptionsOriginal);
        }
      } else {
        setAccountOptions(accountOptionsOriginal);
      }
    } catch (error) {
      console.error(error);
    }
  }
  function searchUserSubAccounts(event: any) {
    try {
      if (event.query || event.query !== "") {
        const filteredSubaccounts = subaccountOptionsOriginal.filter((x) =>
          x.description.toLowerCase().includes(event.query.toLowerCase())
        );
        if (filteredSubaccounts.length > 0) {
          setSubaccountOptions(filteredSubaccounts);
        } else {
          setSubaccountOptions(subaccountOptionsOriginal);
        }
      } else {
        setSubaccountOptions(subaccountOptionsOriginal);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function loadContries(query: string) {
    try {
      const { status, data } = await sipcoAxiosService.get("/paises-options", {
        params: {
          descripcion: query.toUpperCase(),
        },
      });
      if (status === 200) {
        const contries = data.map((x: any) => {
          return {
            value: x.codPais,
            description: `${x.descripcion} (${x.codPais})`,
          };
        });
        if (query) {
          setCountryOptions(
            contries.filter((x: any) =>
              x.description.toLowerCase().includes(query.toLowerCase())
            )
          );
        } else {
          setCountryOptions(contries);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function loadClients(query: string, country: any) {
    try {
      const { status, data } = await sipcoAxiosService.get(
        "/clientes-options",
        {
          params: {
            descripcion: query.toUpperCase(),
            codPais: country.value,
          },
        }
      );
      if (status === 200) {
        const clients = data.map((item: any) => ({
          value: item.codCliente,
          description: `${item.descripcion} (${item.codCliente})`,
        }));

        if (props.allClientsOptions) {
          clients.unshift({
            codCliente: "",
            description: "TODOS",
          });
        }
        if (query) {
          setClientOptions(
            clients.filter((x: any) =>
              x.description.toLowerCase().includes(query.toLowerCase())
            )
          );
        } else {
          setClientOptions(clients);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function loadAccounts(query: string, country: any) {
    try {
      const { status, data } = await sipcoAxiosService.get("/cuentas-options", {
        params: {
          descripcion: query.toUpperCase(),
          codPais: country.value,
        },
      });
      if (status === 200) {
        const accounts = data.map((item: any) => ({
          value: item.codCuenta,
          description: `${item.descripcion} (${item.codCuenta})`,
        }));
        if (query) {
          setAccountOptions(
            accounts.filter((x: any) =>
              x.description.toLowerCase().includes(query.toLowerCase())
            )
          );
        } else {
          setAccountOptions(accounts);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function loadSubAccounts(query: string, account: any) {
    try {
      const { status, data } = await sipcoAxiosService.get(
        "/subcuentas-options",
        {
          params: {
            codCuenta: account.value,
            descripcion: query.toUpperCase(),
          },
        }
      );
      if (status === 200) {
        const subAccounts = data.map((item: any) => ({
          value: item.codSubcuenta,
          description: `${item.descripcion} (${item.codSubcuenta})`,
        }));
        console.log("subAccounts,", subAccounts);
        if (query) {
          setSubaccountOptions(
            subAccounts.filter((x: any) =>
              x.description.toLowerCase().includes(query.toLowerCase())
            )
          );
        } else {
          setSubaccountOptions(subAccounts);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  function handleUserCountrySelection(event: AutoCompleteChangeEvent) {
    try {
      let securityFilterValues = {
        ...values.securityValues,
        country: event.value,
      };
      if (user.nivel > 0) {
        const selectedCountry = securityFilters.find(
          (x) => x.country.value === event.value.value
        );
        if (selectedCountry) {
          const availableClients = selectedCountry.clients;

          if (availableClients.length > 0) {
            setClientOptionsOriginal(
              availableClients.map((x) => {
                return new DropdownValueDTO<string>(
                  x.client.value,
                  `(${x.client.value}) ${x.client.description}`
                );
              })
            );
            securityFilterValues.client = new DropdownValueDTO<string>(
              availableClients[0].client.value,
              `(${availableClients[0].client.value}) ${availableClients[0].client.description}`
            );
            const selectedClient = availableClients.find(
              (x) => x === availableClients[0]
            );

            if (selectedClient) {
              const availableAccounts = selectedClient.accounts;

              if (availableAccounts.length > 0) {
                setAccountOptionsOriginal(
                  availableAccounts.map((x) => {
                    return new DropdownValueDTO<number>(
                      x.account.value,
                      `(${x.account.value}) ${x.account.description}`
                    );
                  })
                );
                securityFilterValues.account = new DropdownValueDTO<number>(
                  availableAccounts[0].account.value,
                  `(${availableAccounts[0].account.value}) ${availableAccounts[0].account.description}`
                );

                const selectedAccount = availableAccounts.find(
                  (x) => x === availableAccounts[0]
                );

                if (selectedAccount) {
                  const availableSubAccounts = selectedAccount.subAccounts;

                  if (availableSubAccounts.length > 0) {
                    setSubaccountOptionsOriginal(
                      availableSubAccounts.map((x) => {
                        return new DropdownValueDTO<number>(
                          x.value,
                          `(${x.value}) ${x.description}`
                        );
                      })
                    );
                    securityFilterValues.subaccount =
                      new DropdownValueDTO<number>(
                        availableSubAccounts[0].value,
                        `(${availableSubAccounts[0].value}) ${availableSubAccounts[0].description}`
                      );
                  }
                }
              }
            }
          }
        }
      }
      setValues({ ...values, securityValues: securityFilterValues });
    } catch (error) {
      console.error(error);
    }
  }

  console.log("Security Filter Value =>", values.securityValues);

  return (
    <div className="filter-security-options">
      {user.nivel > 0 ? (
        <>
          {props.country && (
            <div className="option">
              <label htmlFor="country">
                <LanguageProvider id="1499" alt="Pais" />
              </label>
              <AutoComplete
                value={values.securityValues.country}
                onChange={handleUserCountrySelection}
                dropdown
                completeMethod={searchUserCountries}
                suggestions={countryOptions}
                field="description"
                disabled={countryOptionsOriginal.length <= 1}
              />
            </div>
          )}
          {props.client && (
            <div className="option">
              <label htmlFor="clients">
                <LanguageProvider id="488" alt="Cliente" />
              </label>
              <AutoComplete
                value={values.securityValues.client}
                onChange={(e) => {
                  setValues({
                    ...values,
                    securityValues: {
                      ...values.securityValues,
                      client: e.value,
                    },
                  });
                }}
                dropdown
                completeMethod={searchUserClients}
                suggestions={clientOptions}
                field="description"
                // TODO - Entender motivo do back parar ed responder e verificar solução adequada para clientOptionsOriginal
                //  n vir como array e quebrar a montagem dos elementos em tela, adicionado solucação provisoria '?'  - Lucas Silva Silvestre  - 03/09/2029
                disabled={clientOptionsOriginal?.length <= 1}
              />
            </div>
          )}
          {props.account && (
            <div className="option">
              <label htmlFor="accounts">
                <LanguageProvider id="1791" alt="Conta" />
              </label>
              <AutoComplete
                value={values.securityValues.account}
                onChange={(e) => {
                  setValues({
                    ...values,
                    securityValues: {
                      ...values.securityValues,
                      account: e.value,
                    },
                  });
                }}
                dropdown
                completeMethod={searchUserAccounts}
                suggestions={accountOptions}
                field="description"
                disabled={accountOptionsOriginal.length <= 1}
              />
            </div>
          )}
          {props.subaccount && (
            <div className="option">
              <label htmlFor="subaccounts">
                <LanguageProvider id="4791" alt="Subcuenta" />
              </label>
              <AutoComplete
                value={values.securityValues.subaccount}
                onChange={(e) => {
                  setValues({
                    ...values,
                    securityValues: {
                      ...values.securityValues,
                      subaccount: e.value,
                    },
                  });
                }}
                dropdown
                // completeMethod={searchUserSubAccounts}
                completeMethod={searchSubaccount}
                suggestions={subaccountOptions}
                field="description"
                disabled={subaccountOptionsOriginal.length <= 1}
              />
            </div>
          )}
        </>
      ) : (
        <>
          {props.country && (
            <div className="option">
              <label htmlFor="country">
                <LanguageProvider id="1499" alt="Pais" />
              </label>
              <AutoComplete
                value={values.securityValues.country}
                onChange={(e) => {
                  setValues({
                    ...values,
                    securityValues: {
                      ...values.securityValues,
                      country: e.value,
                    },
                  });
                }}
                dropdown
                completeMethod={searchCountry}
                suggestions={countryOptions}
                field="description"
              />
            </div>
          )}
          {props.client && (
            <div className="option">
              <label htmlFor="clients">
                <LanguageProvider id="488" alt="Cliente" />
              </label>
              <AutoComplete
                value={values.securityValues.client}
                onChange={(e) =>
                  setValues({
                    ...values,
                    securityValues: {
                      ...values.securityValues,
                      client: e.value,
                    },
                  })
                }
                id="clients"
                dropdown
                completeMethod={searchClient}
                suggestions={clientOptions}
                field="description"
              />
            </div>
          )}
          {props.account && (
            <div className="option">
              <label htmlFor="accounts">
                <LanguageProvider id="1791" alt="Conta" />
              </label>
              <AutoComplete
                value={values.securityValues.account}
                onChange={(e) =>
                  setValues({
                    ...values,
                    securityValues: {
                      ...values.securityValues,
                      account: e.value,
                    },
                  })
                }
                id="accounts"
                dropdown
                completeMethod={searchAccount}
                suggestions={accountOptions}
                field="description"
              />
            </div>
          )}
          {props.subaccount && (
            <div className="option">
              <label htmlFor="subaccounts">
                <LanguageProvider id="4791" alt="Subcuenta" />
              </label>
              <AutoComplete
                value={values.securityValues.subaccount}
                onChange={(e) =>
                  setValues({
                    ...values,
                    securityValues: {
                      ...values.securityValues,
                      subaccount: e.value,
                    },
                  })
                }
                id="subaccounts"
                dropdown
                completeMethod={searchSubaccount}
                suggestions={subaccountOptions}
                field="description"
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}

const dropdownItemTemplate = (option: any) => {
  return (
    <div className="flex align-items-center">
      <div>{option.description}</div>
    </div>
  );
};

const dropdownSelectedValueTemplate = (option: any, props: DropdownProps) => {
  if (option) {
    return (
      <div className="flex align-items-center">
        <div>{option.description}</div>
      </div>
    );
  }
  return (
    <div className="flex align-items-center">
      <div>{props.placeholder}</div>
    </div>
  );
};
