import React, { useRef, useState } from "react";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ExportButtons from "@shared/components/Export";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { Tag } from "primereact/tag";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";

const DamageHistory: React.FC<{ vinData: any }> = ({ vinData }) => {
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const dt = useRef(null);
  const data = convertDateObjects(vinData?.historicoDanios?.filas);

  const filterColumnsId = [
    0, 16541, 10540, 2490, 21675, 7280, 21836, 16575, 7811, 11097, 3344, 6197,
    20854, 0, 3741, 11314, 14288, 16576, 16577, 16544, 16456, 18096, 18125,
    18097, 18099, 18098, 1153,
  ];
  const columnsNames = [];
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const getRowStyles = (data) => {
    let className = "";

    if (selectedRow && JSON.stringify(selectedRow) === JSON.stringify(data)) {
      className = "highlight-row";
    }

    if (data[13] != null) {
      if (data[13] === 2) {
        className += " repaired ";
      }
      if (data[13] === 0) {
        className += " pending-repair ";
      }
    }
    return className.trim();
  };

  return (
    <div className="DamageHistory">
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>Historico de Daños</span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"DamageHistory"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          rowClassName={getRowStyles}
          filterDisplay="menu"
          selectionMode="single"
          onSelectionChange={(e) => {
            setSelectedRow(e.value);
          }}
          selection={selectedRow}
        >
          {/* <Column field="0" header={columnsNames[0].label} sortable filter /> */}
          <Column field="1" header={columnsNames[1].label} sortable filter />
          <Column field="2" header={columnsNames[2].label} sortable filter />
          <Column field="3" header={columnsNames[3].label} sortable filter />
          <Column field="4" header={columnsNames[4].label} sortable filter />
          <Column field="5" header={columnsNames[5].label} sortable filter />
          <Column field="6" header={columnsNames[6].label} sortable filter />
          <Column field="7" header={columnsNames[7].label} sortable filter />
          <Column field="8" header={columnsNames[8].label} sortable filter />
          <Column field="9" header={columnsNames[9].label} sortable filter />
          <Column field="10" header={columnsNames[10].label} sortable filter />
          <Column field="11" header={columnsNames[11].label} sortable filter />
          <Column field="12" header={columnsNames[12].label} sortable filter />
          {/* <Column field="13" header={columnsNames[13].label} sortable filter /> */}
          <Column field="14" header={columnsNames[14].label} sortable filter />
          <Column field="15" header={columnsNames[15].label} sortable filter />
          <Column field="16" header={columnsNames[16].label} sortable filter />
          <Column field="17" header={columnsNames[17].label} sortable filter />
          <Column field="18" header={columnsNames[18].label} sortable filter />
          <Column field="19" header={columnsNames[19].label} sortable filter />
          <Column field="20" header={columnsNames[20].label} sortable filter />
          <Column field="21" header={columnsNames[21].label} sortable filter />
          <Column field="22" header={columnsNames[22].label} sortable filter />
          <Column field="23" header={columnsNames[23].label} sortable filter />
          <Column field="24" header={columnsNames[24].label} sortable filter />
          <Column field="25" header={columnsNames[25].label} sortable filter />
        </DataTable>
        <div className="tags">
          <Tag value={columnsNames[26].label} className="tag-repaired" />
        </div>
      </Panel>
    </div>
  );
};
export default DamageHistory;
