import React, { useRef, useState } from "react";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import usePageViews from "../../../hooks/usePageViews";

export function TiemposEntrega() {
  const SCREEN_CODE = "con0035_vehiculos_en_taller";
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);
  const filterLabelId = [327, 467, 468, 1151];
  const filterNames = [];
  const filterColumnsId = [
    16478, 1491, 396, 2139, 4724, 2140, 16479, 3625, 1317, 2205, 2245,
  ];
  const columnsNames = [];
  for (let i = 0; i < filterLabelId.length; i++) {
    filterNames.push({
      id: filterLabelId[i],
      label: LanguageProvider({
        id: filterLabelId[i],
        alt: "Error Filter Labels",
      }),
    });
  }
  for (let i = 0; i < filterColumnsId.length; i++) {
    columnsNames.push({
      id: filterColumnsId[i],
      label: LanguageProvider({
        id: filterColumnsId[i],
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    54, 65, 327, 396, 467, 468, 1002, 1151, 1182, 1317, 1491, 1791, 2139, 2140,
    2205, 2245, 3625, 4724, 4791, 5044, 5583, 5627, 5628, 16478, 16479, 16531,
  ];
  for (let i = 0; i < ids.length; i++) {
    mensagens.push({
      id: ids[i],
      label: LanguageProvider({ id: ids[i], alt: "Error Columns Labels" }),
    });
  }
  console.log(mensagens);
  return (
    <div>
      <Filter
        onSearch={setReporte}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
        filterOptions={{
          urlApi: "vehiculos-taller/report",
          parametros: ["vehiculosTaller"],
          customFilters: [
            {
              label: filterNames[0].label,
              variable: "Taller",
              type: "dropdown",
              options: [],
              parametros: ["codPais", "codCuenta", "codSubcuenta"],
              urlApi: "vehiculos-taller/combo/taller",
            },
            {
              label: filterNames[1].label + filterNames[2].label,
              variable: "Fecha",
              type: "date",
            },
            {
              label: filterNames[3].label,
              variable: "Estados",
              type: "dropdown",
              options: [],
              parametros: ["codPais"],
              urlApi: "vehiculos-taller/combo/estado",
            },
          ],
        }}
      />
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>Vehiculos Taller</span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"Vehiculos Taller"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          filterDisplay="menu"
        >
          <Column field="0" header={columnsNames[0].label} sortable filter />
          <Column field="1" header={columnsNames[1].label} sortable filter />
          <Column field="2" header={columnsNames[2].label} sortable filter />
          <Column field="3" header={columnsNames[3].label} sortable filter />
          <Column field="4" header={columnsNames[4].label} sortable filter />
          <Column field="5" header={columnsNames[5].label} sortable filter />
          <Column field="6" header={columnsNames[6].label} sortable filter />
          <Column field="7" header={columnsNames[7].label} sortable filter />
          <Column field="8" header={columnsNames[8].label} sortable filter />
          <Column field="9" header={columnsNames[9].label} sortable filter />
          <Column field="10" header={columnsNames[10].label} sortable filter />
          {/*<Column field="" header={columnsNames[11].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[12].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[13].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[14].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[15].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[16].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[17].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[18].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[19].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[20].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[21].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[22].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[23].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[24].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[25].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[26].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[27].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[28].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[29].label} sortable filter/>*/}
        </DataTable>
      </Panel>
    </div>
  );
}
export default TiemposEntrega;
