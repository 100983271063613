import "./consulta-vin.scss";

import { Accordion, AccordionTab } from "primereact/accordion";
import React, { useRef, useState } from "react";
import { TabPanel, TabView } from "primereact/tabview";

import { AutoComplete } from "primereact/autocomplete";
import DamageHistory from "@pages/queries/consulta-vin/components/DamageHistory";
import { Filter } from "@shared/components/filter/filter";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { LanguageProvider } from "@shared/components/language-provider";
import LocationHistory from "@pages/queries/consulta-vin/components/LocationHistory";
import Messages from "@pages/queries/consulta-vin/components/Messages";
import MoveHistory from "@pages/queries/consulta-vin/components/MoveHistory";
import OcurrenceHistory from "@pages/queries/consulta-vin/components/OccurrenceHistory";
import Operations from "@pages/queries/consulta-vin/components/Operations";
import Third from "@pages/queries/consulta-vin/components/Third";
import { Toast } from "primereact/toast";
import VinView from "@pages/queries/consulta-vin/components/VinView";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Dialog } from "primereact/dialog";
import { ListBox } from "primereact/listbox";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

export function ConsultaVin() {
  const SCREEN_CODE = "con0020_vin_danios";
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const toast = useRef<Toast>(null);

  usePageViews();
  const [vinData, setVinData] = useState(null);
  const [codVin, setCodVin] = useState("");
  const [vinObject, setVinObject] = useState({});
  const [listVinObject, setListVinObject] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState([]);
  const [listClientes, setListClientes] = useState([]);
  const [modal, setModal] = useState(false);
  const [loadingVin, setLoadingVin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeTabIndex] = useState(0);

  const filterLabelId = [
    1491, 54, 1491, 1137, 254, 3701, 2693, 9573, 5854, 22700, 9955,
  ];
  const filterNames = [];

  for (const element of filterLabelId) {
    filterNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Filter Labels",
      }),
    });
  }
  const showError = (id: string, alt: string) => {
    toast.current.show({
      severity: "error",
      summary: "Erro",
      detail: <LanguageProvider id={id} alt={alt} />,
      life: 3000,
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setLoadingVin(true);
      findDatas();
    }
  };

  function findDatas() {
    if (codVin.length >= 8 && codVin.length <= 17) {
      findFirstBy();
    } else {
      setLoadingVin(false);
      return showError(
        "653",
        "Debe ingresar un minimo de 8 y un maximo de 17 dígitos"
      );
    }
  }

  async function findClienteByVin() {
    setSelectedCliente([]);
    try {
      const { status, data } = await sipcoAxiosService.get(
        "report/findClientesVinBy",
        {
          params: {
            codPais: securityFilters.securityValues?.country?.value,
            codCuenta: securityFilters.securityValues?.account?.value,
            codVin: codVin,
          },
        }
      );

      if (status === 200) {
        setListClientes(data);
        if (data.length > 0 || data.length === 1) {
          setSelectedCliente(data[0].descripcion);
          loadVinVinReportData(data[0]);
        }
        setListClientes(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function findFirstBy() {
    try {
      const { status, data } = await sipcoAxiosService.get(
        "report/findFirstBy",
        {
          params: {
            codPais: securityFilters.securityValues?.country?.value,
            codCuenta: securityFilters.securityValues?.account?.value,
            codVin: codVin,
          },
        }
      );

      if (status === 200) {
        if (data.length === 0) {
          setLoadingVin(false);
          return showError("1480", "El VIN no existe");
        }
        
        if (codVin.length === 8) {
          setVinObject({});
          if (data.length > 1) {
            setListVinObject(data);
            setModal(true);
          }else if(data.length === 1){
            setCodVin(data[0])
            setVinObject(data[0]);
          }

        } else if (data.length >= 1) {
          setVinObject(data[0]);
        }
        
        findClienteByVin();
        setLoadingVin(false);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function loadVinVinReportData(cliente) {
    if (!selectedCliente) {
      return;
    }
    try {
      const reportResult = await sipcoAxiosService.get("/report/vinReport", {
        params: {
          codPais: securityFilters.securityValues?.country?.value,
          codCuenta: securityFilters.securityValues?.account?.value,
          codCliente: cliente.id,
          codVin: codVin,
        },
      });

      if (reportResult) {
        const { status, data } = reportResult;
        if (status === 200 && data) {
          setVinData(data);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  function selectionVin() {
    if (vinObject === null || Object.keys(vinObject).length === 0) {
      return showError("681", "Seleccione un vin");
    }
    setModal(false);
    findFullByPk(vinObject);
  }

  function findFullByPk(vin) {
    setCodVin(vin?.id);
    setLoadingVin(false);
  }

  return (
    <div className="consulta-vin">
      <Toast ref={toast} position="top-center" />

      <Filter
        onSearch={findDatas}
        securityOptions={{
          account: true,
          client: false,
          country: true,
          subaccount: false,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="filter-options">
            <div style={{ position: "relative" }} className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[0].id} alt="Vin" />
              </label>

              <IconField
                style={{ position: "absolute", bottom: "35%", right: "5px" }}
              >
                <InputIcon
                  disabled={loadingVin}
                  className="pi pi-spin pi-spinner"
                  style={{ zIndex: `${loadingVin ? 1 : 0}` }}
                />
              </IconField>
              <AutoComplete
                value={codVin}
                suggestions={listVinObject}
                onKeyDown={handleKeyDown}
                onChange={(e) => {
                  setSelectedCliente([]);
                  setCodVin(e.value.trim());
                }}
                field="id"
              />
            </div>

            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[1].id} alt="Cliente" />
              </label>
              <AutoComplete
                value={selectedCliente}
                suggestions={listClientes}
                completeMethod={findClienteByVin}
                onChange={(e) => {
                  setSelectedCliente(e?.value?.descripcion);
                  loadVinVinReportData(e?.value);
                }}
                field="descripcion"
                dropdown
                forceSelection
              />
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Accordion multiple activeIndex={[0, 1]}>
        <AccordionTab header="Dados VIN">
          <TabView activeIndex={activeTabIndex}>
            <TabPanel header={filterNames[2].label}>
              <VinView vinData={vinData} />
            </TabPanel>
            <TabPanel header={filterNames[3].label}>
              <MoveHistory vinData={vinData} />
            </TabPanel>
            <TabPanel header={filterNames[4].label}>
              <DamageHistory vinData={vinData} />
            </TabPanel>
            <TabPanel header={filterNames[5].label}>
              <Messages vinData={vinData} />
            </TabPanel>
            <TabPanel header={filterNames[6].label}>
              <Operations vinData={vinData} />
            </TabPanel>
            <TabPanel header={filterNames[7].label}>
              <LocationHistory vinData={vinData} />
            </TabPanel>
            {/* <TabPanel header={filterNames[8].label}>
              <DigitalizedDocuments vinData={vinData} />
            </TabPanel> */}
            <TabPanel header={filterNames[9].label}>
              <OcurrenceHistory vinData={vinData} />
            </TabPanel>
            <TabPanel header={filterNames[10].label}>
              <Third vinData={vinData} />
            </TabPanel>
          </TabView>
        </AccordionTab>
      </Accordion>
      <div
        style={{ zIndex: 10000 }}
        className="card flex justify-content-center"
      >
        <Dialog
          visible={modal}
          modal
          header={
            <span className="font-bold white-space-nowrap">
              <LanguageProvider id={681} alt="Seleccione un vin" />
            </span>
          }
          footer={
            <Button
              label="Ok"
              icon="pi pi-check"
              onClick={selectionVin}
              autoFocus
            />
          }
          style={{ width: "80rem"}}
          onHide={() => {
            if (!modal) return;
            setModal(false);
          }}
        >
          <div className="m-0" style={{ padding: "1rem" }}>
  
            <DataTable value={listVinObject} selectionMode="single" selection={vinObject} tableStyle={{ minWidth: "50rem" }}
            onSelectionChange={(e) => {
              setVinObject(e.value)
              findClienteByVin()
              }} dataKey="id" > 
              <Column field="id" header="Vin"></Column>
              <Column
                field="cliente.descripcion" header={<LanguageProvider id={"24080"} alt="Cliente" />}
              ></Column>
              <Column field="marca.descripcion" header={<LanguageProvider id={23890} alt="Marca" />}></Column>
              <Column field="modelo.descripcion" header={<LanguageProvider id={23865} alt="Modelo" />}></Column>
              <Column field="stockPlaya.calle" header={<LanguageProvider id={990} alt="Calle" />}></Column>
              <Column field="stockPlaya.columna" header={<LanguageProvider id={991} alt="Columna" />}></Column>
            </DataTable>
          </div>
        </Dialog>
      </div>
    </div>
  );
}
